import React from "react";
import {
  SectionCover,
  SectionHead,
} from "../../Components/PublicStyles/StyledComp";
import styled from "styled-components";
import HeaderForHover from "../../Components/Header/HeaderForHover";
import { FadeOut, MoveTop } from "../Home/Introduct";
import { useInView } from "react-intersection-observer";

function Abt_section_3() {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });
  return (
    <SectionDiv ref={ref} className={inView ? "animate" : ""}>
      <HeaderForHover />
      <SectionHead>WHY CHOOSE US</SectionHead>
      <ChoiceContainer>
        <ChoiceDiv>
          <img
            src={require("../../Asset/about/Rectangle-71 1.jpg")}
            alt="icon"
          />
          <SpecialText>
            <h6>Tailored Solutions</h6>
          </SpecialText>
        </ChoiceDiv>
        <ChoiceDiv>
          <img src={require("../../Asset/about/depth 1.jpg")} alt="icon" />
          <SpecialText>
            <h6>Comprehensive Services</h6>
          </SpecialText>
        </ChoiceDiv>
        <ChoiceDiv>
          <img src={require("../../Asset/about/risk 1.jpg")} alt="icon" />
          <SpecialText>
            <h6>Client-Centric Approach</h6>
          </SpecialText>
        </ChoiceDiv>
        <ChoiceDiv>
          <img src={require("../../Asset/about/earth.jpg")} alt="icon" />
          <SpecialText>
            <h6>Innovation and Insight</h6>
          </SpecialText>
        </ChoiceDiv>
      </ChoiceContainer>
    </SectionDiv>
  );
}

export default Abt_section_3;
export const SectionDiv = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 40px 0 20px 40px;
  position: relative;
  &.animate h5 {
    animation: ${MoveTop} 3s forwards;
  }
  h5 {
    font-weight: 700;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    margin: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 20px 20px;
    &.animate h5 {
      animation: none;
    }
    h5 {
      font-family: "Montserrat", sans-serif;
      font-size: 28px;
      font-weight: 700;
      line-height: 33.6px;
      text-align: left;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1510px) {
  }
  @media (min-width: 1511px) and (max-width: 1700px) {
    padding-top: 30px;
  }
  @media (min-width: 1701px) {
    padding-top: 99px;
    h5 {
      font-family: "Montserrat", sans-serif;
      font-size: 41.6px;
      font-weight: 800;
      line-height: 50.71px;
      text-align: left;
    }
  }
`;
export const ChoiceContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 35px;
  row-gap: 40px;
  justify-content: center;
  padding: 1.7rem 0 0 0;
  &.animate {
    animation: ${FadeOut} 3s forwards;
  }
  @media (max-width: 768px) {
    column-gap: 0;
    row-gap: 20px;
    padding: 0px 0 0 0;
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1509px) {
  }
  @media (min-width: 1510px) and (max-width: 1700px) {
    padding: 20px 0 0 0;
    column-gap: 50px;
    row-gap: 30px;
  }
  @media (min-width: 1701px) {
    padding: 40px 0 0 0;
    column-gap: 50px;
    row-gap: 30px;
  }
`;
export const ChoiceDiv = styled.div`
  flex-basis: calc(100vw / 3);
  position: relative;
  display: flex;
  background: linear-gradient(
    185.52deg,
    rgba(0, 0, 0, 0) 74.88%,
    rgba(0, 0, 0, 0.2) 95.6%
  );
  border: 1px solid #ffffff;
  border-radius: 10px;
  height: calc(73vh / 2);
  object-fit: cover;
  justify-content: center;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    filter: grayscale(100%);
  }
  @media (max-width: 768px) {
    height: 226px;
    flex-basis: calc(100% / 1);
  }
  @media (min-width: 1510px) and (max-width: 1700px) {
    /* height: 300px; */
  }

  @media (min-width: 1701px) {
    height: 360px;
  }
  /* @media (min-width: 769px) and (max-width: 992px) {
    height: 160px;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    height: 160px;
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    height: 240px;
  }
   */
`;
export const SpecialText = styled.div`
  position: absolute;
  bottom: 10px;
  h6 {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #f0f0f0;
  }
  @media (max-width: 768px) {
    bottom: 0;
    h6 {
      font-family: "Founders Grotesk", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 26.4px;
      text-align: left;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
  }
  @media (min-width: 1441px) and (max-width: 1510px) {
  }
  @media (min-width: 1511px) and (max-width: 1535px) {
    h6 {
      font-size: 24.8px;
      font-weight: 600;
      line-height: 32.11px;
    }
  }
  @media (min-width: 1701px) {
    h6 {
      font-size: 28.8px;
      font-weight: 600;
      line-height: 35.11px;
      text-align: left;
    }
  }
`;
