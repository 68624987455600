import React from "react";
import ca1 from "../../Asset/home/working-in-office-2023-11-27-05-31-14-utc-1a-modifiedk.jpg";
import ca2 from "../../Asset/home/Group 65jtr.jpg";
import ca3 from "../../Asset/home/3.jpg";
import ca4 from "../../Asset/home/4.png";
import ca5 from "../../Asset/home/5.png";
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselCaption,
} from "mdb-react-ui-kit";
import styled, { keyframes } from "styled-components";

function Banner() {
  return (
    <Outer>
      <div className="d-flex justify-content-center p-0">
        <CustomCarousel showIndicators fade>
          <CustomItem itemId={1}>
            <img src={ca1} className="d-block w-100" alt="..." />
            <BannerText>
              Sculpt Your Prosperity <br />
              with the Superior
              <br />
              Insights of
              <br />
              <TypingText text="CLOVERFIELD WEALTH000">
                CLOVERFIELD WEALTH
              </TypingText>
            </BannerText>
            {/* <Caption>
              <ScrollingText>
                <span>Record growth in investment</span>
                <span>Free Taxation Advisory</span>
                <span>Free Taxation Advisory</span>
                <span>Start A New Plan</span>
              </ScrollingText>
            </Caption> */}
          </CustomItem>

          <CustomItem itemId={2}>
            <img src={ca2} className="d-block w-100" alt="..." />
            <CarouselInnerText width="500px">
              <h1>Early Retirement: Your Goals, Our Guidance</h1>
              <p>
                Chart your course to early retirement with our expert advice and
                customized financial strategies tailored to your goals.
              </p>
            </CarouselInnerText>
          </CustomItem>

          <CustomItem itemId={3}>
            <img src={ca3} className="d-block w-100" alt="..." />
            <CarouselInnerText width="500px" top="38%">
              <h1>Propel Your Business to New Heights with Expert Solutions</h1>
              <p>
                Elevate your expansion with tailored financing solutions crafted
                for growth. Unlock new opportunities and drive success through
                our expert guidance and flexible funding options. Partner with
                us to realize your business ambitions.
              </p>
            </CarouselInnerText>
          </CustomItem>
          <CustomItem itemId={4}>
            <img src={ca4} className="d-block w-100" alt="..." />
            <CarouselInnerText width="500px">
              <h1>Elucidate Your Child’s Future with Confidence</h1>
              <p>
                Invest wisely in your child's education with our expert
                guidance, ensuring a secure future and access to top-tier
                education opportunities. Trust us to optimize your savings for
                maximum benefits.
              </p>
            </CarouselInnerText>
          </CustomItem>
          <CustomItem itemId={5}>
            <img src={ca5} className="d-block w-100" alt="..." />
            <CarouselInnerText width="550px">
              <h1>Manifest a Timeless Legacy</h1>
              <p>
                Ensure your family's future with meticulously crafted legacy
                planning. Through strategic investments and insightful
                foresight, establish a robust foundation of success, stability,
                and opportunity for generations to come. Begin now to sculpt a
                prosperous future and create a lasting heritage for your loved
                ones.
              </p>
            </CarouselInnerText>
          </CustomItem>
        </CustomCarousel>
      </div>
    </Outer>
  );
}

export default Banner;

const Outer = styled.div`
  width: 100vw;
  height: calc(100vh - 89px);
  background-color: #000;
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-right: 0;
    height: 100%;
    width: 100%;
    padding: 10px 20px;
  }
`;

const CustomCarousel = styled(MDBCarousel)`
  width: 100%;
  max-height: calc(100vh - 95px);
  justify-content: center;
  .carousel-inner {
    padding: 0 7rem 10px 7rem;
    height: calc(100vh - 20vh);
    width: 100vw;
    object-fit: cover;
    margin-top: 0;
    transition: transform 5s ease; /* Added transition duration */
  }
  .carousel-indicators li {
    border-radius: 50%;
    height: 15px;
    width: 15px;
    border: 1px solid #ffffffb2;
    background: #d9d9d959;
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 0px;
    .carousel-inner {
      height: 100%;
      padding: 0px 0px;
      width: 100%;
    }
    .carousel-indicators li {
      margin-right: 10px;
      height: 10px;
      width: 10px;
    }
  }

  @media (min-width: 769px) and (max-width: 992px) {
    /* max-width: 700px; */
  }

  @media (min-width: 993px) and (max-width: 1200px) {
    /* max-width: 800px;
    max-height: 400px; */
  }

  @media (min-width: 1201px) and (max-width: 1440px) {
    /* max-width: 1000px;
    max-height: 450px; */
  }

  @media (min-width: 1441px) and (max-width: 1700px) {
    /* max-width: 1250px;
    max-height: 650px; */
    padding-bottom: 30px;
  }

  @media (min-width: 1701px) {
    /* max-width: 1600px;
    max-height: 700px; */
    padding-bottom: 100px;
  }
`;

const CustomItem = styled(MDBCarouselItem)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }
`;
const BannerText = styled.div`
  position: absolute;
  padding: 0 30px;
  top: 35%;
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 44px;
  text-align: left;
  width: 600px;
  height: 180px;

  @media (max-width: 576px) {
    font-size: 17px;
    font-weight: 700;
    line-height: 20.4px;
    width: 100%;
    padding: 0 25px;
    height: 100%;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    font-size: 28px;
    line-height: 31px;
    width: 100%;
    padding: 0 25px;
    height: 100%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 32px;
    line-height: 36px;
    width: 70%;
    padding: 0 25px;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    /* Styles for large devices */
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    font-size: 50px;
    line-height: 58px;
    width: 60%;
    padding: 0 25px;
  }

  @media (min-width: 1701px) {
    font-size: 61px;
    line-height: 73.2px;
    width: 60%;
    padding: 0 25px;
  }
`;

const TypingText = styled.span`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 10px;
  border-right: 4px solid;
  font-family: "Founders Grotesk", sans-serif;
  font-weight: 700;
  animation: typing 4s steps(${(props) => props.text.length}) 1s forwards
    alternate;
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: ${(props) =>
        props.text
          .length}ch; /* Dynamically set width to match character length */
    }
  }
`;

const scroll = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const Caption = styled(MDBCarouselCaption)`
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  color: white;
  padding: 10px;
  height: 50px;
  width: 100%;
  background: #68686899;
  display: flex;
  align-items: center;
  text-align: left;
  overflow: hidden;
  @media (max-width: 767.98px) {
    bottom: 10%;
    padding: 5px;
    height: 40px;
  }

  @media (min-width: 768px) and (max-width: 992px) {
    bottom: 10%;
    padding: 8px;
    height: 45px;
  }

  @media (min-width: 993px) and (max-width: 1200px) {
    bottom: 10%;
    padding: 10px;
    height: 50px;
  }

  @media (min-width: 1201px) and (max-width: 1440px) {
    bottom: 10%;
    padding: 12px;
    height: 50px;
  }

  @media (min-width: 1441px) and (max-width: 1700px) {
    bottom: 10%;
    padding: 15px;
    height: 60px;
  }

  @media (min-width: 1701px) {
    bottom: 15%;
    padding: 20px;
    height: 60px;
  }
`;

const ScrollingText = styled.div`
  display: flex;
  white-space: nowrap;
  animation: ${scroll} 15s linear infinite;

  span {
    font-family: "Founders Grotesk", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #000000;
    margin-right: 35px;
    margin-left: 30px;

    @media (max-width: 700px) {
      font-size: 14px;
      line-height: 20px;
      margin-right: 15px;
      margin-left: 15px;
    }

    @media (min-width: 701px) and (max-width: 992px) {
      font-size: 15px;
      line-height: 22px;
      margin-right: 20px;
      margin-left: 20px;
    }

    @media (min-width: 993px) and (max-width: 1200px) {
      font-size: 16px;
      line-height: 24px;
      margin-right: 25px;
      margin-left: 25px;
    }

    @media (min-width: 1201px) and (max-width: 1440px) {
      font-size: 17px;
      line-height: 26px;
      margin-right: 30px;
      margin-left: 30px;
    }

    @media (min-width: 1441px) and (max-width: 1700px) {
      font-size: 18px;
      line-height: 28px;
      margin-right: 35px;
      margin-left: 35px;
    }

    @media (min-width: 1701px) {
      font-size: 20px;
      line-height: 28px;
      margin-right: 45px;
      margin-left: 40px;
    }
  }
`;

const CarouselInnerText = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 0 30px;
  top: ${(props) => (props.top ? props.top : "40%")};
  color: #fff;
  font-family: "Founders Grotesk", sans-serif;
  text-align: left;
  width: ${(props) => (props.width ? props.width : "450px")};

  height: 180px;
  h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 43.2px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  @media (max-width: 576px) {
    width: 100%;
    padding: 0 15px;
    top: 15%;
    h1 {
      font-size: 17px;
      line-height: 20px;
    }
    p {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (min-width: 577px) and (max-width: 768px) {
    width: 70%;
    padding: 0 15px;
    h1 {
      font-size: 28px;
      line-height: 34px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 70%;
    padding: 0 15px;
    h1 {
      font-size: 32px;
      line-height: 38px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
    }
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    width: 50%;
    h1 {
      font-size: 52px;
      line-height: 58px;
    }
    p {
      font-size: 20px;
      line-height: 30px;
    }
  }

  @media (min-width: 1701px) {
    width: 50%;
    h1 {
      font-size: 52px;
      line-height: 62px;
    }
    p {
      font-size: 24px;
      line-height: 34px;
    }
  }
`;
