import React from "react";
import { HomeDiv } from "../Home/Home";
import Header from "../../Components/Header/Header";
import { InsightDiv } from "./Insight";
import { FaArrowRight } from "react-icons/fa6";
import city from "../../Asset/home/insight/city.png";
import gif from "../../Asset/home/insight/gif.gif";
import styled from "styled-components";
import HeaderForHover from "../../Components/Header/HeaderForHover";
import { useInView } from "react-intersection-observer";
function Insight1() {
  return (
    <InsightDiv>
      <div className="d-flex justify-content-evenly w-100 align-items-center column_gap">
      <ContentDiv className="mt-4">
        <h1>
          Monthly
          <br /> Statistics
        </h1>
        <p>
          At Cloverfield Wealth, we believe that informed decisions are the
          cornerstone of financial success. Besides providing wealth management
          solutions, we give you an insight into the latest market trends,
          financial strategies, and expert analyses to help you navigate the
          complexities of wealth management
        </p>
      </ContentDiv>
      <CustomContentDiv>
        <div className="contentcontainer">
          <h1>20 %</h1>
          <div className="contentImage">
            <img src={city} alt="" />
          </div>
        </div>
        <p>
          January statistics for the growth of Cloverfield in terms of wealth
          management services
        </p>
      </CustomContentDiv>
      </div>
    </InsightDiv>
  );
}

export default Insight1;

export const ContentDiv = styled.div`
  background: #2626261a;
  width: 330px;
  height: auto;
  row-gap: 20px;
  flex-direction: column;
  display: flex;
  text-align: left;
  background-image: url(${gif});
  background-repeat: no-repeat;
  p {
    font-family: "Founders Grotesk", sans-serif;
    color: #a6a6a6;
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  h1 {
    color: #ffffff;
    font-weight: 600;
    font-family: "Founders Grotesk", sans-serif;
    margin: 0;
  }
  @media (max-width: 768px) {
    background-image: none;
    width: 100%;
    margin-bottom: 2rem;
    br {
      display: none;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    width: 520px;
    h1 {
      font-size: 50px;
      line-height: 68px;
      text-align: left;
      margin-bottom: 2rem;
    }
    p {
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
      text-align: left;
    }
  }
  @media (min-width: 1701px) {
    width: 520px;
    h1 {
      font-size: 65px;
      line-height: 78px;
      text-align: left;
      margin-bottom: 2rem;
    }
    p {
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
      text-align: left;
    }
  }
`;
export const CustomContentDiv = styled(ContentDiv)`
  border: 1px solid #ffffff33;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding: 40px;
  width: 320px;
  height: 230px;
  background-image: none;
  p {
    font-family: "Founders Grotesk", sans-serif;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
  }
  .contentcontainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    .contentImage {
      width: 80px;
      height: 80px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h1 {
      font-family: "Founders Grotesk", sans-serif;
      margin-top: 15px;
      font-weight: 600;
    }
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 55px;
      line-height: 66px;
      text-align: left;
    }
    p {
      font-size: 15px;
      font-weight: 400;
      line-height: 20.4px;
      text-align: left;
    }
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    width: 400px;
    height: 280px;
    padding: 30px;
    .contentcontainer {
      h1 {
        font-size: 65px;
        font-weight: 700;
        line-height: 114px;
        text-align: left;
        margin-top: 3.5rem;
        margin-bottom: 0;
      }
      .contentImage {
        width: 80px;
        height: 80px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 26px;
      text-align: left;
    }
  }
  @media (min-width: 1701px) {
    width: 500px;
    height: 300px;
    padding: 40px;
    .contentcontainer {
      h1 {
        font-size: 65px;
        font-weight: 700;
        line-height: 114px;
        text-align: left;
        margin-top: 3.5rem;
      }
      .contentImage {
        width: 80px;
        height: 80px;
      }
    }
    p {
      font-size: 20px;
      font-weight: 300;
      line-height: 30px;
      text-align: left;
    }
  }
`;
