import React from "react";
import { SectionDiv } from "./Abt_section_3";
import styled from "styled-components";
import HeaderForHover from "../../Components/Header/HeaderForHover";
import { useInView } from "react-intersection-observer";
import { FadeOut } from "../Home/Introduct";
import { SectionHead } from "../../Components/PublicStyles/StyledComp";

function Abt_section_4() {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });
  return (
    <SectionD ref={ref} className={inView ? "animate" : ""}>
      <HeaderForHover />
      <SectionHead>CORE VALUES</SectionHead>
      <BullContainer>
        <ImageContainer>
          <img
            src={require("../../Asset/about/Rectangle-79.jpg")}
            alt="values"
          />
        </ImageContainer>
        <ForMobile>
          <BullTextContainer>
            <h6>Integrity</h6>
          </BullTextContainer>
          <BullTextContainerDown>
            <h6>Trust</h6>
          </BullTextContainerDown>
          <BullTextContainerTop>
            <h6>Innovation</h6>
          </BullTextContainerTop>
          <BullTextContainerRight>
            <h6>Excellence</h6>
          </BullTextContainerRight>
        </ForMobile>
      </BullContainer>
    </SectionD>
  );
}

export default Abt_section_4;
const SectionD = styled(SectionDiv)`
  position: relative;
  background: radial-gradient(
    51.66% 66.06% at 55.32% 48.34%,
    #292929 0%,
    #000000 100%
  );
`;
const BullContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  &.animate {
    animation: ${FadeOut} 3s forwards;
  }
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.01) 0%,
    rgba(237, 222, 157, 0.01) 100%
  );

  @media (max-width: 768px) {
    padding-top: 30px;
    flex-direction: column;
    /* align-items: flex-start; */
  }
`;
const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 30vh);
  width: calc(100vw - 60vw);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    height: 350px;
    width: 100%;
  }
`;
const BullTextContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 60px;
  background: linear-gradient(
    90deg,
    rgba(61, 60, 59, 0.01) 0%,
    rgba(237, 222, 157, 0.01) 100%
  );

  backdrop-filter: blur(8px);
  box-shadow: 0px 0px 4px 0px #ffffff;
  border-radius: 15px;
  left: 22%;
  top: 30%;
  h6 {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
  @media (max-width: 768px) {
    position: static;
    left: 0;
    top: 0;
    height: 40.67px;
    width: 100%;
    border-radius: 10px;
    padding-top: 5px;
    h6 {
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.2px;
      text-align: left;
    }
  }
  @media (min-width: 1400px) {
    width: 270px;
    height: 80px;
    h6 {
      font-size: 22px;
    }
  }
`;

const BullTextContainerDown = styled(BullTextContainer)`
  top: 70%;
  @media (max-width: 700px) {
    top: 55%;
    left: 23%;
  }
`;
const BullTextContainerTop = styled(BullTextContainer)`
  top: 30%;
  left: 64%;
  @media (max-width: 700px) {
    left: 23%;
    top: 40%;
  }
  @media (min-width: 1400px) {
    left: 64%;
  }
`;
const BullTextContainerRight = styled(BullTextContainer)`
  top: 70%;
  left: 64%;
  @media (max-width: 700px) {
    top: 70%;
    left: 23%;
  }
  @media (min-width: 1400px) {
    left: 64%;
  }
`;

const ForMobile = styled.div`
  @media (max-width: 768px) {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 30px;
  }
`;
