import React from "react";
import styled from "styled-components";
import foot from "../../Asset/Rectangle-141.jpg";
import logo from "../../Asset/home/Social/Cw logo 1 1.png";
import instagram from "../../Asset/home/Social/instagram.png";
import facebook from "../../Asset/home/Social/facebbok.png";
import linkedin from "../../Asset/home/Social/linkedin.png";
import youtube from "../../Asset/home/Social/youtube.png";
import { InnerText } from "./Philosaophy";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <Footers>
      <div className="imgContainer">
        <img src={foot} alt="" />
        <InnerTextNew className="d-flex align-items-center justify-content-center">
          <span>Start Your Journey With Cloverfield</span>
        </InnerTextNew>
      </div>
      <div className="footerContainer">
        <div className="logoContainer">
          <img src={logo} alt="" />
        </div>
        <div className="allContainer">
          <ForMobile className="w-100">
            <LinkList>
              <ul>
                <Link to={"/"}>
                  <li>Home</li>
                </Link>
                <Link to={"/about"}>
                  <li>About Us</li>
                </Link>
                <li>Services/Products</li>
                <li>Blog/News</li>

                <Link to={"/?section=2"}>
                  <li>Partners/Affiliates</li>
                </Link>

                <li>Careers/Join Us</li>
              </ul>
              <ul>
                <Link to={"/contact"}>
                  <li>Contact Us</li>
                </Link>
                <li>FAQ/Help Center</li>
                <li>Support/Help</li>
                <li>
                  Privacy Policy/
                  <br />
                  Terms of Service
                </li>
                <SocioList>
                  {/* <IconContainer>
                    <img src={instagram} alt="" />
                  </IconContainer> */}
                  <a
                    href="https://www.facebook.com/profile.php?id=61561328405757"
                    target="_blank"
                  >
                    <IconContainer>
                      <img src={facebook} alt="" />
                    </IconContainer>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/cloverfield-wealth-8412a8316/"
                    target="_blank"
                  >
                    <IconContainer>
                      <img src={linkedin} alt="" />
                    </IconContainer>
                  </a>
                  {/* <IconContainer>
                    <img src={youtube} alt="" />
                  </IconContainer> */}
                </SocioList>
              </ul>
            </LinkList>
          </ForMobile>
          <ForLarge>
            <LinkList>
              <ul>
                <Link to={"/"}>
                  <li>Home</li>
                </Link>
                <Link to={"/about"}>
                  <li>About Us</li>
                </Link>
                <li>Services/Products</li>
                <li>Blog/News</li>
              </ul>
              <ul>
                <Link to={"/contact"}>
                  <li>Contact Us</li>
                </Link>
                <li>FAQ/Help Center</li>
                <li>Support/Help</li>
                <li>Privacy Policy/Terms of Service</li>
              </ul>
              <ul>
                <Link to={"/?section=2"}>
                  <li>Partners/Affiliates</li>
                </Link>
                <li>Careers/Join Us</li>
              </ul>
            </LinkList>
            <SocioList>
              {/* <IconContainer>
                <img src={instagram} alt="" />
              </IconContainer> */}
              <a
                href="https://www.facebook.com/profile.php?id=61561328405757"
                target="_blank"
              >
                <IconContainer>
                  <img src={facebook} alt="" />
                </IconContainer>
              </a>
              <a
                href="https://www.linkedin.com/in/cloverfield-wealth-8412a8316/"
                target="_blank"
              >
                <IconContainer>
                  <img src={linkedin} alt="" />
                </IconContainer>
              </a>
              {/* <IconContainer>
                <img src={youtube} alt="" />
              </IconContainer> */}
            </SocioList>
          </ForLarge>
        </div>
        <Line />
        <div className="policy w-100">
          <span>© 2023 Cloverfield All rights reserved.</span>
        </div>
      </div>
    </Footers>
  );
}

export default Footer;

// Styled Components

const Footers = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  width: 100vw;
  height: 100vh;
  @media (min-width: 1511px) and (max-width: 1535px) {
    padding-top: 20px;
  }
  @media (min-width: 1701px) {
    padding-top: 40px;
  }
  .imgContainer {
    width: 100%;
    height: 43vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 768px) {
      height: 113px;
      img {
        max-height: 113px;
      }
    }
    @media (min-width: 1441px) and (max-width: 1700px) {
      height: 300px;
    }
    @media (min-width: 1701px) {
      height: 453px;
    }
  }
  .footerContainer {
    width: 100%;
    height: 60vh;
    .logoContainer {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      img {
        width: 230px;
        height: 100px;
      }
      @media (min-width: 1511px) and (max-width: 1535px) {
        height: 110px;
        img {
          width: 260px;
          height: 110.48px;
        }
      }

      @media (min-width: 1701px) {
        height: 160px;
        img {
          width: 355px;
          height: 160.48px;
        }
      }
    }
  }
  .allContainer {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
  }
  .policy {
    display: flex;
    padding: 10px 30px;
    width: 100%;
    span {
      font-family: "Founders Grotesk", sans-serif;
      font-size: 12px;
      color: #fff;
      font-weight: 400;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    .imgContainer {
      width: 100%;
      height: 113px;
      img {
        width: 100%;
        max-height: 113px;
        object-fit: cover;
      }
    }
    .footerContainer {
      width: 100%;
      height: 100%;
      .logoContainer {
        width: 100%;
        height: 113px;
        img {
          width: 50%;
          height: 60%;
        }
      }
      .allContainer {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0;
        margin: 0;
      }
      .policy {
        width: 100%;
        padding: 0;
        justify-content: center;
        span {
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 21.6px;
          color: #fff;
          text-align: center;
        }
      }
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    .policy {
      span {
        font-size: 12px;
      }
    }
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
    .policy {
      span {
        font-size: 12px;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1510px) {
    .allContainer {
      height: 70%;
    }
    .policy {
      span {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 1511px) and (max-width: 1535px) {
    .allContainer {
      height: 55%;
    }
    .policy {
      span {
        font-size: 14px;
      }
    }
    @media (min-width: 1701px) {
      .allContainer {
        height: 55%;
      }
      .policy {
        span {
          font-size: 14px;
        }
      }
    }
  }
`;
const LinkList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 57.5%;
  height: 100%;
  ul,
  li {
    list-style: none;
    color: #fff;
    font-family: "Founders Grotesk", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 31.6px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    margin-top: 30px;
    ul,
    li {
      width: 100%;
      font-size: 16px;
      font-family: "Founders Grotesk", sans-serif;
      font-weight: 500;
      line-height: 19.2px;
      text-align: left;
      margin-bottom: 25px;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 100%;
    padding-right: 20px;
    justify-content: center;
    ul {
      font-size: 16px;
      line-height: 24px;
    }
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    ul {
      font-size: 14px;
      line-height: 26px;
    }
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
    ul,
    li {
      font-size: 14px;
      line-height: 38px;
    }
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    ul,
    li {
      font-size: 18px;
      line-height: 42px;
    }
  }
  @media (min-width: 1701px) {
    ul,
    li {
      font-size: 18px;
      line-height: 53px;
    }
  }
`;
const SocioList = styled.div`
  display: flex;
  width: 39.5%;
  margin: 0;
  column-gap: 40px;
  align-items: center;
  justify-content: center;
  padding-right: 50px;
  margin-top: 30px;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 0;
    column-gap: 10px;
    padding-right: 0;
    justify-content: left;
    margin-top: 0;
  }
`;
const IconContainer = styled.div`
  display: flex;
  width: 43px;
  height: 43px;

  @media (max-width: 576px) {
    width: 23px;
    height: 23px;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    width: 26px;
    height: 26px;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 28px;
    height: 28px;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 34px;
    height: 34px;
  }
  @media (min-width: 1201px) and (max-width: 1511px) {
    width: 43px;
    height: 43px;
  }
  @media (min-width: 1512px) and (max-width: 1700px) {
    width: 48px;
    height: 48px;
  }
  @media (min-width: 1701px) {
    width: 58px;
    height: 58px;
  }
`;

const Line = styled.div`
  width: 94.5%;
  height: 1px;
  background-color: #ffffff4d;
  margin-left: 30px;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 60px;
  }
`;
const ForLarge = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 769px) {
    display: none;
  }
`;
const ForMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const InnerTextNew = styled.div`
  position: absolute;
  display: flex;
  color: #fff;
  padding: 0 5.9rem;
  font-size: 40px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0 1rem;
  }
  @media (min-width: 1701px) {
    font-size: 64px;
    font-weight: 700;
    line-height: 78.02px;
    text-align: left;
  }
`;
