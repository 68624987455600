import styled from "styled-components";
import { MoveTop } from "../../Pages/Home/Introduct";

export const SectionHead = styled.h5`
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 27.6px;
  text-align: left;
  color: #fff;
  text-transform: uppercase;
  &.animate {
    animation: ${MoveTop} 3s forwards;
  }
  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 768px) {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    &.animate {
      animation: none;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
    font-size: 24px;
  }

  @media (min-width: 993px) and (max-width: 1200px) {
    font-size: 28px;
  }

  @media (min-width: 1201px) and (max-width: 1510px) {
    font-size: 28px;
  }
  @media (min-width: 1511px) and (max-width: 1535px) {
    font-size: 33px;
  }
  @media (min-width: 1536px) and (max-width: 1700px) {
    font-size: 34px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: left;
  }
  @media (min-width: 1701px) and (max-width: 1998px) {
    font-size: 41.6px;
    font-weight: 800;
    line-height: 50.71px;
    text-align: left;
  }
  @media (min-width: 1999px) {
    font-size: 44.6px;
    font-weight: 800;
    line-height: 50.71px;
    text-align: left;
  }
`;

export const SectionCover = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
`;
