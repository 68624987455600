import React, { useEffect, useState } from "react";
import { CustomInnerText, CustomSection, ViewDiv } from "./Sln_section_1";
import mutual from "../../Asset/solution/Group 73.png";
import portfolio from "../../Asset/solution/Group 72q.png";
import alternate from "../../Asset/solution/jaguar-s-type-76342341.jpg";
import offshore from "../../Asset/solution/flight.jpg";
import ring from "../../Asset/solution/ring 56.png";
import crown from "../../Asset/solution/crown15.jpg";
import baby from "../../Asset/solution/familya.jpg";
import chess from "../../Asset/solution/Group 780.png";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { RiCloseLargeFill } from "react-icons/ri";
import Header from "../../Components/Header/Header";
import { GoArrowLeft } from "react-icons/go";
import { HomeDiv } from "../Home/Home";
import { Modal } from "react-bootstrap";

function Solution_view_page(props) {
  const sharedId = props.id;

  return (
    <Modal fullscreen show={props.show} style={{ zIndex: "99999" }}>
      <CuHomeDiv>
        {/* <HeaderForHover /> */}
        <ForSmall>
          <Header />
        </ForSmall>
        <CustomSection>
          <CloseButton onClick={props.onHide}>
            <RiCloseLargeFill />
          </CloseButton>
          <MobileClose onClick={props.onHide}>
            <GoArrowLeft />
          </MobileClose>
          {sharedId === "1" ? (
            <ViewDiv>
              <img src={mutual} alt="" />
              <CustomInnerText>
                <h3>MUTUAL FUNDS</h3>
                <p>
                  A mutual fund is a pooled investment vehicle where investors'
                  funds are professionally managed to invest in a diversified
                  portfolio of securities, providing an opportunity for
                  individuals to access a diversified investment portfolio
                  without directly managing individual securities
                </p>
              </CustomInnerText>
            </ViewDiv>
          ) : sharedId === "2" ? (
            <ViewDiv>
              <img src={portfolio} alt="" />
              <CustomInnerText>
                <h3>Portfolio Management Services</h3>
                <p>
                  Portfolio Management Services (PMS) are a type of investment
                  services that offer customized and personalized solutions to
                  High Networth Individuals who want to invest in various assets
                  such as equity, debt, gold, Real estate etc. The portfolio
                  Managers will continuously monitor the market trends and make
                  adjustments to the portfolio allocation to maximize returns
                  and minimize risk.
                </p>
              </CustomInnerText>
            </ViewDiv>
          ) : sharedId === "3" ? (
            <ViewDiv>
              <img src={alternate} alt="" />
              <CustomInnerText>
                <h3>ALTERNATE INVESTMENT</h3>
                <p>
                  Alternate Investment Funds (AIF) is a privately pooled
                  investment vehicle that invests in alternative asset classes
                  such as private equity, venture capital, hedge funds, real
                  estate, commodities & derivatives.
                </p>
              </CustomInnerText>
            </ViewDiv>
          ) : sharedId === "4" ? (
            <ViewDiv>
              <img src={offshore} alt="" />
              <CustomInnerText>
                <h3>Offshore Investments</h3>
                <p>
                  Offshore Investments provide an opportunity to diversify your
                  portfolio by spreading risk across different asset classes and
                  geographical locations. By investing in foreign markets,
                  industries and currencies you can reduce your exposure to
                  risks associated with a single country’s economic or political
                  fluctuations.
                </p>
              </CustomInnerText>
            </ViewDiv>
          ) : sharedId === "5" ? (
            <ViewDiv>
              <img src={ring} alt="" />
              <CustomInnerText>
                <h3>BOND</h3>
                <p>
                  Bond is a debt instrument in which an issuer borrows money
                  from the lender (bond holder) and in return is obliged to pay
                  interest on the principle amount. The interest is called the
                  coupon. Bonds can be a safe investment option especially for
                  risk averse investors and can be a good long term investment
                  for people who aren’t experienced with the stock markets.
                </p>
              </CustomInnerText>
            </ViewDiv>
          ) : sharedId === "6" ? (
            <ViewDiv>
              <img src={crown} alt="" />
              <CustomInnerText>
                <h3>Structures</h3>
                <p>
                  Structured Products are investments which provide a return
                  based on the performance of an asset. This asset can cover the
                  equity, index, fund, interest rate, currency, commodity or
                  property markets. The payoff and level of capital at risk can
                  be predefined.
                </p>
              </CustomInnerText>
            </ViewDiv>
          ) : sharedId === "7" ? (
            <ViewDiv>
              <img src={baby} alt="" />
              <CustomInnerText>
                <h3>lIFE & NON-LIFE INSURANCE</h3>
                <p>
                  In the insurance world policies are broadly classified into
                  two categories Life Insurance & Non Life Insurance. Life
                  insurance policies broadly covers two aspects, one is to
                  indemnify the loss of life of the policyholder and the second
                  is to also act as a savings product which allows the policy
                  holder to accumulate wealth. Non Life Insurance is also known
                  as general insurance and it covers assets like vehicle, home,
                  travel, accident etc.
                </p>
              </CustomInnerText>
            </ViewDiv>
          ) : sharedId === "8" ? (
            <ViewDiv>
              <img src={chess} alt="" />
              <CustomInnerText>
                <h3> Estate & Succession Planning</h3>
                <p>
                  Estate and succession planning focuses on the seamless
                  transfer of assets and responsibilities within a family or
                  business. By outlining a clear roadmap for transition
                  individuals can avoid potential conflict and ensure that their
                  whishes are carried out precisely post their lifetimes.
                </p>
              </CustomInnerText>
            </ViewDiv>
          ) : null}
        </CustomSection>
      </CuHomeDiv>
    </Modal>
  );
}

export default Solution_view_page;

const CloseButton = styled.div`
  display: flex;
  width: calc(100% - 20%);
  font-size: larger;
  justify-content: right;
  cursor: pointer;
  :hover {
    color: #fff;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;
const MobileClose = styled.div`
  display: flex;
  justify-content: left;
  color: #fff;
  font-size: 24px;
  width: 100%;
  height: 20px;
  margin: 20px 0;
  @media (min-width: 900px) {
    display: none;
  }
`;
const ForSmall = styled.div`
  @media (min-width: 900px) {
    display: none;
  }
`;
export const CuHomeDiv = styled(HomeDiv)`
  height: 100vh;
  width: 100vw;
  position: relative;
  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    background-color: #000;
    padding-bottom: 40vh;
  }
`;
