import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header/Header";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Solution from "./Pages/Solution/Solution";
import Insight from "./Pages/Insight/Insight";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contactus from "./Pages/Contact/Contactus";
import Solution_view_page from "./Pages/Solution/Solution_view_page";
import Partners from "./Pages/Home/Partners";
import { useEffect, useState } from "react";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home isMobile={isMobile} />} />
          {/* <Route path="/home" element={<Home />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/solution" element={<Solution />} />
          <Route path="/insight" element={<Insight />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="sln_view_p/:id" element={<Solution_view_page />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
