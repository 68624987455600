import React, { useState } from "react";
import {
  ChoiceContainer,
  ChoiceDiv,
  SectionDiv,
  SpecialText,
} from "../About/Abt_section_3";
import ring from "../../Asset/solution/ring 56.png";
import crown from "../../Asset/solution/crown15.jpg";
import baby from "../../Asset/solution/familya.jpg";
import chess from "../../Asset/solution/Group 780.png";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import HeaderForHover from "../../Components/Header/HeaderForHover";
import { useInView } from "react-intersection-observer";
import { FadeOut } from "../Home/Introduct";
import Solution_view_page from "./Solution_view_page";

function Sln_section_2() {
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const history = useNavigate();
  const handleChoiceClick = (id) => {
    setId(id);
    setShow(true);
  };
  return (
    <CuSectionDiv>
      <HeaderForHover />
      <ChoiceContainer>
        <ChoiceDiv onClick={() => handleChoiceClick("5")}>
          <img src={ring} alt="icon" />
          <SpecialText>
            <h6>Bond</h6>
          </SpecialText>
        </ChoiceDiv>
        <ChoiceDiv onClick={() => handleChoiceClick("6")}>
          <img src={crown} alt="icon" />
          <SpecialText>
            <h6>Structures</h6>
          </SpecialText>
        </ChoiceDiv>
        <ChoiceDiv onClick={() => handleChoiceClick("7")}>
          <img src={baby} alt="icon" />
          <SpecialText>
            <h6>Life & Non Life Insurance</h6>
          </SpecialText>
        </ChoiceDiv>
        <ChoiceDiv onClick={() => handleChoiceClick("8")}>
          <img src={chess} alt="icon" />
          <SpecialText>
            <h6> Estate & Succession Planning</h6>
          </SpecialText>
        </ChoiceDiv>
      </ChoiceContainer>
      <Solution_view_page show={show} id={id} onHide={() => setShow(false)} />
    </CuSectionDiv>
  );
}

export default Sln_section_2;

const CuSectionDiv = styled(SectionDiv)`
  padding-top: 50px;
  width: calc(100vw - 2.8vw);
  &.animate {
    animation: ${FadeOut} 3s forwards;
  }
  @media (max-width: 768px) {
    padding-top: 0;
  }
  @media (min-width: 1441px) {
    padding-top: 100px;
  }
`;
