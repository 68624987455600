import React, { useState } from "react";
import {
  ChoiceContainer,
  ChoiceDiv,
  SectionDiv,
  SpecialText,
} from "../About/Abt_section_3";
import styled from "styled-components";
import mutual from "../../Asset/solution/Group 73.png";
import portfolio from "../../Asset/solution/Group 72q.png";
import alternate from "../../Asset/solution/jaguar-s-type-76342341.jpg";
import offshore from "../../Asset/solution/flight.jpg";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { FadeOut } from "../Home/Introduct";
import Solution_view_page from "./Solution_view_page";

function Sln_section_1() {
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const history = useNavigate();
  const handleChoiceClick = (id) => {
    setId(id);
    setShow(true);
  };
  return (
    <CustomSection>
      <CustomChoice>
        <ChoiceDiv onClick={() => handleChoiceClick("1")}>
          <img src={mutual} alt="icon" />
          <SpecialText>
            <h6>Mutual Fund</h6>
          </SpecialText>
        </ChoiceDiv>
        <ChoiceDiv onClick={() => handleChoiceClick("2")}>
          <img src={portfolio} alt="icon" />
          <SpecialText>
            <h6>Portfolio Management Services</h6>
          </SpecialText>
        </ChoiceDiv>
        <ChoiceDiv onClick={() => handleChoiceClick("3")}>
          <img src={alternate} alt="icon" />
          <SpecialText>
            <h6>Alternate Investments</h6>
          </SpecialText>
        </ChoiceDiv>
        <ChoiceDiv onClick={() => handleChoiceClick("4")}>
          <img src={offshore} alt="icon" />
          <SpecialText>
            <h6>Offshore Investments</h6>
          </SpecialText>
        </ChoiceDiv>
      </CustomChoice>
      <Solution_view_page show={show} id={id} onHide={() => setShow(false)} />
    </CustomSection>
  );
}

export default Sln_section_1;

export const CustomSection = styled(SectionDiv)`
  position: relative;
  height: calc(100% - 60px);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &.animate {
    animation: ${FadeOut} 3s forwards;
  }
  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    padding: 20px;
    justify-content: unset;
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    padding-bottom: 30px;
    padding-top: 10px;
  }
  @media (min-width: 1701px) {
    padding-top: 20px;
    padding-bottom: 20px;
    height: calc(100% - 100px);
  }
`;
const CustomChoice = styled(ChoiceContainer)`
  row-gap: 10px;
  padding: 0;
  padding-top: 10px;
  @media (max-width: 768px) {
    row-gap: 20px;
  }
`;
export const ViewDiv = styled.div`
  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  padding: 10px 12rem 10px 12rem;
  justify-content: end;
  align-items: center;
  img {
    border-radius: 15px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(100%);
  }
  @media (max-width: 768px) {
    height: 100%;
    padding: 10px 0;
    /* margin-top: -10rem; */
    flex-direction: column-reverse;
    justify-content: start;
    width: 100%;
    img {
      height: 218px;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
    height: 100%;
    padding: 10px 6.5;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    height: 100%;
    padding: 10px 9rem;
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    height: 100%;
    padding: 10px 12rem;
  }
  @media (min-width: 1701px) {
    height: 100%;
    padding: 30px 14rem;
  }
`;
export const CustomInnerText = styled.div`
  display: flex;
  width: 420px;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  h3 {
    color: #fff;
    font-family: "Founders Grotesk", sans-serif;
    font-size: 22px;
    font-weight: 400;
    position: relative; /* Needed for the ::after pseudo-element */
    text-transform: uppercase;
  }

  h3::after {
    content: "";
    display: block;
    width: 50%; /* Adjust this to control the underline length */
    height: 2px; /* Adjust this to control the underline thickness */
    background-color: #fff;
    position: absolute;
    bottom: -10px; /* Adjust this to control the space between text and underline */
    left: 25%; /* Adjust this to control the position of the underline */
    border-radius: 3px;
  }
  p {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    width: 100%;
    position: unset;
    padding-right: 0;
    h3 {
      font-size: 23px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      position: unset;
    }
    h3::after {
      display: none;
    }
    p {
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.02em;
      text-align: left;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    width: 600px;
    padding-right: 30px;
    h3 {
      font-size: 26px;
    }
    h3::after {
      bottom: -10px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
      margin-top: 30px;
    }
  }
  @media (min-width: 1701px) {
    width: 650px;
    padding-right: 30px;
    h3 {
      font-size: 34px;
    }
    h3::after {
      bottom: -13px;
    }
    p {
      font-size: 20px;
      line-height: 31px;
      margin-top: 40px;
    }
  }
`;
