import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "../../Asset/logo.png";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink as MDBNavbarLinkBase,
} from "mdb-react-ui-kit";
import { useNavigate, useLocation } from "react-router-dom";

function Header() {
  const [openNav, setOpenNav] = useState(false);
  const [activeLink, setActiveLink] = useState("/");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleLinkClick = (path) => {
    setActiveLink(path);
    navigate(path);
    setOpenNav(false); // Close the overlay when a link is clicked
  };

  return (
    <Headers>
      <StyledNavbar expand="lg" light bgColor="black">
        <MDBContainer fluid>
          <MDBNavbarBrand href="#">
            <IconImg src={logo} height="80" alt="" loading="lazy" />
          </MDBNavbarBrand>
          <Toggler onClick={() => setOpenNav(!openNav)} open={openNav}>
            <div></div>
            <div></div>
            <div></div>
          </Toggler>
          <MDBNavbarNav className="ms-auto d-none d-lg-flex d-flex justify-content-end align-items-center">
            <MDBNavbarItem>
              <CustomNavbarLink
                aria-current="page"
                isActive={activeLink === "/"}
                onClick={() => handleLinkClick("/")}
              >
                Home
              </CustomNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <CustomNavbarLink
                isActive={activeLink === "/about"}
                onClick={() => handleLinkClick("/about")}
              >
                About
              </CustomNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <CustomNavbarLink
                isActive={activeLink === "/solution"}
                onClick={() => handleLinkClick("/solution")}
              >
                Our Solution
              </CustomNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <CustomNavbarLink
                isActive={activeLink === "/insight"}
                onClick={() => handleLinkClick("/insight")}
              >
                Insights
              </CustomNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <CustomNavbarLink
                isActive={activeLink === "/contact"}
                onClick={() => handleLinkClick("/contact")}
              >
                Contact Us
              </CustomNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <CustomNavbarLink isActive={activeLink === "/contact"}>
                <a href="https://invest.cloverfieldwealth.com" target="_blank">
                  <button>Login</button>
                </a>
              </CustomNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBContainer>
      </StyledNavbar>
      <Overlay open={openNav}>
        <MDBNavbarNav>
          <MDBNavbarItem style={{marginTop:'15px'}}>
            <CustomNavbarLink
              aria-current="page"
              isActive={activeLink === "/"}
              onClick={() => handleLinkClick("/")}
            >
              Home
            </CustomNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <CustomNavbarLink
              isActive={activeLink === "/about"}
              onClick={() => handleLinkClick("/about")}
            >
              About
            </CustomNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <CustomNavbarLink
              isActive={activeLink === "/solution"}
              onClick={() => handleLinkClick("/solution")}
            >
              Our Solution
            </CustomNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <CustomNavbarLink
              isActive={activeLink === "/insight"}
              onClick={() => handleLinkClick("/insight")}
            >
              Insights
            </CustomNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <CustomNavbarLink
              isActive={activeLink === "/contact"}
              onClick={() => handleLinkClick("/contact")}
            >
              Contact Us
            </CustomNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <CustomNavbarLink isActive={activeLink === "/contact"}>
              <a href="https://invest.cloverfieldwealth.com" target="_blank">
                <button>Login</button>
              </a>
            </CustomNavbarLink>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </Overlay>
    </Headers>
  );
}

export default Header;
export const IconImg = styled.img`
  @media (max-width: 576px) {
    width: 114.22px;
    height: 40px;
  }
  @media (min-width: 577px) and (max-width: 768px) {
    width: 139.22px;
    height: 45px;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    width: 144.22px;
    height: 47px;
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    width: 140.22px;
    height: 50px;
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
    width: 150.52px;
    height: 55px;
  }
  @media (min-width: 1441px) and (max-width: 1510px) {
    width: 210.52px;
    height: 85px;
  }
  @media (min-width: 1511px) and (max-width: 1700px) {
    width: 210.52px;
    height: 87px;
  }
  @media (min-width: 1701px) {
    width: 260px;
    height: 110px;
  }
`;
const Headers = styled.div`
  background: #000000;
  width: 100vw;
  margin: 0;
`;

export const StyledNavbar = styled(MDBNavbar)`
  width: 100%;
  height: auto;
  padding: 10px 30px 0 30px;
  background-color: #000;
  z-index: 10001; /* Ensure the navbar is above the overlay */
  @media (max-width: 768px) {
    padding: 5px 10px;
  }
  @media (min-width: 1510px) and (max-width: 1700px) {
    padding: 15px 30px 0 30px;
  }
  @media (min-width: 1701px) {
    padding: 39px 30px 10px 30px;
  }
`;

const Toggler = styled.div`
  width: 30px;
  height: 24px;
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  z-index: 10002; /* Ensure the toggler is above the navbar */

  div {
    width: 100%;
    height: 3px;
    background-color: #fff;
    border-radius: 2px;
    transition: all 0.3s ease;

    &:nth-child(2) {
      width: 80%; /* Center line shorter */
      /* align-self: center; */
    }
  }

  ${({ open }) =>
    open &&
    `
    div {
      &:nth-child(1) {
        transform: rotate(45deg);
        transform-origin: 5% 50%;
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
        transform-origin: 5% 50%;
      }
    }
  `}

  @media (max-width: 991px) {
    display: flex; /* Show toggler only on screens smaller than 992px */
  }
`;

const Overlay = styled.div`
  position: fixed;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10000;

  .navbar-nav {
    text-align: center;
  }

  @media (min-width: 992px) {
    display: none; /* Hide overlay on larger screens */
  }
`;

export const CustomNavbarLink = styled(MDBNavbarLinkBase)`
  color: #fff;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  transition: all 0.3s ease-in-out;
  margin-left: 30px;
  button {
    border: none;
    border-radius: 5px;
    padding: 2.5px 20px;
  }
  @media (max-width: 900px) {
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    margin: 25px 0 25px 0;
    font-family: "Founders Grotesk", sans-serif;
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  @media (min-width: 1701px) {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  ${({ isActive }) =>
    isActive &&
    `
    text-decoration: underline;
    text-decoration-color: #fff;
    text-underline-offset: 5px;
  `}

  &:hover {
    color: #fff;
    text-decoration: underline;
    text-decoration-color: #fff;
    text-underline-offset: 5px;
  }
`;
