import React from "react";
import { HomeDiv } from "../Home/Home";
import Header from "../../Components/Header/Header";
import { ContentDiv, CustomContentDiv } from "./Insight1";
import { FaArrowRight } from "react-icons/fa6";
import city from "../../Asset/home/insight/city.png";
import styled from "styled-components";
import { InsightDiv } from "./Insight";
import HeaderForHover from "../../Components/Header/HeaderForHover";
import { useInView } from "react-intersection-observer";

function Insight_2() {
  return (
    <InsightDiv>
      <div className="d-flex justify-content-evenly w-100 align-items-center column_gap">
      <ContentDiv>
        <h1>
          Highest <br /> Investment <br />
          Record
        </h1>
        <p>
          Cloverfield is a torchbearer in wealth management and has achieved a
          record-high investment, demonstrating unparalleled growth and client
          trust. Our success underscores our commitment to providing exceptional
          financial planning and investment strategies tailored to meet your
          long-term goals. Our dedicated team remains focused on providing
          personalized and innovative solutions to help you achieve your
          financial goals. Together, we are building a prosperous future. Join
          us and experience the difference.
        </p>
      </ContentDiv>
      <CustomContentDiv>
        <div className="contentcontainer">
          <h1>20 %</h1>
          <div className="contentImage">
            <img src={city} alt="" />
          </div>
        </div>
        <p>
          January statistics for the growth of Cloverfield in terms of wealth
          management services
        </p>
      </CustomContentDiv>
      </div>
    </InsightDiv>
  );
}

export default Insight_2;
