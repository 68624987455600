import React from "react";
import { Introduction } from "../Home/Introduct";
import intro from "../../Asset/home/piano.jpg";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";


function Abt_Section_1() {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });
  return (
    <CustomIntro ref={ref} className={inView ? "animate" : ""}>
      <div className="intro">
        <h1>ABOUT US</h1>
        <p>
        Welcome to Cloverfield Wealth, a pioneering wealth management firm dedicated to guiding individuals and corporates towards financial prosperity and security. As a newly established company, we bring a fresh perspective to the industry, fueled by innovation, expertise, and a steadfast commitment to our clients' success. At Cloverfield Wealth, we understand that each client's financial journey is unique, which is why we offer personalized wealth management solutions tailored to their specific needs and aspirations. With a focus on transparency, integrity, and client-centricity, we strive to build lasting relationships based on trust and mutual respect. Our experienced team is dedicated to empowering our clients to make informed decisions, navigate financial complexities, and achieve their long-term financial goals with confidence. At Cloverfield Wealth, your financial future begins here. Join us and experience a partnership that prioritizes your success, ensuring you are well-equipped to face the future with assurance and clarity.
        </p>
      </div>
      <div className="imageCon">
        <img src={intro} alt="" />
      </div>
    </CustomIntro>
  );
}

export default Abt_Section_1;

export const CustomIntro = styled(Introduction)`
  height: calc(100% - 115px);
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: 100%;
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    padding-bottom: 30px;
  }
  @media (min-width: 1701px) {
    padding-bottom: 40px;
  }
`;
