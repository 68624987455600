import React, { useEffect, useState } from "react";
import Sln_section_1 from "./Sln_section_1";
import { HomeDiv, Indicatored, Section } from "../Home/Home";
import Indicator from "../../Components/Indicator/Indicator";
import Footer from "../Home/Footer";
import Header from "../../Components/Header/Header";
import Sln_section_2 from "./Sln_section_2";
import ReactPageScroller from "react-page-scroller";
import { useSearchParams } from "react-router-dom";

function Solution() {
  const [currentPage, setCurrentPage] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const handleIndicatorClick = (page) => {
    if (isMobile) {
      const section = document.getElementById(`section-${page}`);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        setCurrentPage(page - 1);
      }
    } else {
      setCurrentPage(page - 1);
    }
  };

  const handlePageChange = (number) => {
    setCurrentPage(number);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <HomeDiv>
      {isMobile ? (
        <>
          <Header />
          <Section id="section-1" className="d-flex flex-column">
            <h6 className="externalHead">SOLUTION</h6>
            <Sln_section_1 />
          </Section>
          <Section id="section-2">
            <Sln_section_2 />
          </Section>
        </>
      ) : (
        <ReactPageScroller
          pageOnChange={handlePageChange}
          customPageNumber={currentPage}
        >
          <>
            <Header />
            <Sln_section_1 />
          </>
          <Sln_section_2 />
        </ReactPageScroller>
      )}
      <Indicatored>
        <Indicator
          numberOfSlides={2}
          currentPage={currentPage + 1}
          onIndicatorClick={handleIndicatorClick}
        />
      </Indicatored>
    </HomeDiv>
  );
}

export default Solution;
