import React, { useState, useEffect } from "react";
import styled from "styled-components";
import logo from "../../Asset/logo.png";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink as MDBNavbarLinkBase,
} from "mdb-react-ui-kit";
import { useNavigate, useLocation } from "react-router-dom";
import { CustomNavbarLink, IconImg, StyledNavbar } from "./Header";

function HeaderForHover() {
  const [openNav, setOpenNav] = useState(false);
  const [activeLink, setActiveLink] = useState("/");
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleLinkClick = (path) => {
    setActiveLink(path);
    navigate(path);
    setOpenNav(false); // Close the overlay when a link is clicked
  };
  return (
    <Header>
      <StyledNavbar expand="lg" light bgColor="black">
        <MDBContainer fluid>
          <MDBNavbarBrand href="#">
            <IconImg src={logo} height="80" alt="" loading="lazy" />
          </MDBNavbarBrand>
          <MDBNavbarNav className="ms-auto d-none d-lg-flex d-flex justify-content-end align-items-center">
            <MDBNavbarItem>
              <CustomNavbarLink
                aria-current="page"
                isActive={activeLink === "/"}
                onClick={() => handleLinkClick("/")}
              >
                Home
              </CustomNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <CustomNavbarLink
                isActive={activeLink === "/about"}
                onClick={() => handleLinkClick("/about")}
              >
                About
              </CustomNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <CustomNavbarLink
                isActive={activeLink === "/solution"}
                onClick={() => handleLinkClick("/solution")}
              >
                Our Solution
              </CustomNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <CustomNavbarLink
                isActive={activeLink === "/insight"}
                onClick={() => handleLinkClick("/insight")}
              >
                Insights
              </CustomNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <CustomNavbarLink
                isActive={activeLink === "/contact"}
                onClick={() => handleLinkClick("/contact")}
              >
                Contact Us
              </CustomNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <CustomNavbarLink isActive={activeLink === "/contact"}>
                <a href="https://invest.cloverfieldwealth.com" target="_blank">
                  <button>Login</button>
                </a>
              </CustomNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBContainer>
      </StyledNavbar>
    </Header>
  );
}

export default HeaderForHover;

export const Header = styled.div`
  background: #000000;
  width: 100vw;
  margin: 0;
  opacity: 0; /* Ensure initial state is hidden */
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  &:hover {
    opacity: 1;
    z-index: 99999;
  }
`;
