import React, { useEffect, useState } from "react";
import { HomeDiv, Indicatored, Section } from "../Home/Home";
import Indicator from "../../Components/Indicator/Indicator";
import Abt_Section_1 from "./Abt_Section_1";
import Footer from "../Home/Footer";
import Abt_section_2 from "./Abt_section_2";
import Abt_section_3 from "./Abt_section_3";
import Abt_section_4 from "./Abt_section_4";
import Abt_section_5 from "./Abt_section_5";
import Header from "../../Components/Header/Header";
import ReactPageScroller from "react-page-scroller";

function About() {
  const [currentPage, setCurrentPage] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const handleIndicatorClick = (page) => {
    if (isMobile) {
      const section = document.getElementById(`section-${page}`);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        setCurrentPage(page - 1);
      }
    } else {
      setCurrentPage(page - 1);
    }
  };

  const handlePageChange = (number) => {
    setCurrentPage(number);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <HomeDiv>
      {isMobile ? (
        <>
          <Header />
          <Section id="section-1">
            <Abt_Section_1 />
          </Section>
          <Section id="section-2">
            <Abt_section_2 />
          </Section>
          <Section id="section-3">
            <Abt_section_3 />
          </Section>
          <Section id="section-4">
            <Abt_section_4 />
          </Section>
          <Section id="section-5">
            <Abt_section_5 />
          </Section>
          <Section id="section-6">
            <Footer />
          </Section>
        </>
      ) : (
        <ReactPageScroller
          pageOnChange={handlePageChange}
          customPageNumber={currentPage}
        >
          <>
            <Header />
            <Abt_Section_1 />
          </>
          <Abt_section_2 />
          <Abt_section_3 />
          <Abt_section_4 />
          <Abt_section_5 />
          <Footer />
        </ReactPageScroller>
      )}
      <Indicatored>
        <Indicator
          numberOfSlides={6}
          currentPage={currentPage + 1}
          onIndicatorClick={handleIndicatorClick}
        />
      </Indicatored>
    </HomeDiv>
  );
}

export default About;
