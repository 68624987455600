import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import risk from "../../Asset/home/Process/risk.png";
import need from "../../Asset/home/Process/need.png";
import real from "../../Asset/home/Process/real.png";
import regular from "../../Asset/home/Process/regular.png";
import maintain from "../../Asset/home/Process/maintain.png";
import styled from "styled-components";
import { SectionHead } from "../../Components/PublicStyles/StyledComp";
import { Modal, ModalBody } from "react-bootstrap";
import HeaderForHover from "../../Components/Header/HeaderForHover";
import { useInView } from "react-intersection-observer";
import { FadeOut, MoveTop } from "./Introduct";

function Process() {
  const [show, setShow] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });
  return (
    <Processed ref={ref}>
      <HeaderForHover />
      <SectionHead className={inView ? "animate" : ""}>OUR PROCESS</SectionHead>
      <div className="subdiv">
        <Card className={inView ? "animate" : ""}>
          <div className="cards" onClick={() => setShow(1)}>
            <div className="top">
              <span>01.</span>
              <FaArrowRight />
            </div>
            <div className="image">
              <img src={risk} alt="" />
            </div>
            <div className="content">
              <h6>Risk profile-based asset allocation</h6>
              <p>
                Discover how our wealth management experts customize investment
                strategies based on your risk tolerance, ensuring a balanced
                portfolio aligned with your financial goals
              </p>
            </div>
          </div>
          <div className="cards" onClick={() => setShow(2)}>
            <div className="top">
              <span>02.</span>
              <FaArrowRight />
            </div>
            <div className="image">
              <img src={need} alt="" />
            </div>
            <div className="content">
              <h6>Need based product selection</h6>
              <p>
                Our wealth management approach prioritizes your unique financial
                needs, goals, and circumstances. Need-based product selection is
                at the heart of our strategy, ensuring that every investment
                recommendation is tailored specifically to you.
              </p>
            </div>
          </div>
          <div className="cards" onClick={() => setShow(3)}>
            <div className="top">
              <span>03.</span>
              <FaArrowRight />
            </div>
            <div className="image">
              <img src={real} alt="" />
            </div>
            <div className="content">
              <h6>Real-Time Portfolio Visibility at Your Fingertips </h6>
              <p>
                Our advanced wealth management platform offers real-time
                portfolio visibility at your fingertips, empowering you to stay
                informed and make timely decisions with confidence
              </p>
            </div>
          </div>
          <div className="cards" onClick={() => setShow(4)}>
            <div className="top">
              <span>04.</span>
              <FaArrowRight />
            </div>
            <div className="image">
              <img src={regular} alt="" />
            </div>
            <div className="content">
              <h6>Regular Portfolio Check-ins </h6>
              <p>
                Our wealth management service offers structured, consistent
                portfolio reviews, providing you with the confidence and insight
                needed to navigate the ever-changing financial landscape.
              </p>
            </div>
          </div>
          <div className="cards" onClick={() => setShow(5)}>
            <div className="top">
              <span>05.</span>
              <FaArrowRight />
            </div>
            <div className="image">
              <img src={maintain} alt="" />
            </div>
            <div className="content">
              <h6>Maintaining Portfolio Alignment</h6>
              <p>
                Our wealth management service emphasizes continuous portfolio
                alignment to ensure your investments remain on track, adapting
                to both market changes and your evolving personal circumstances.
              </p>
            </div>
          </div>
        </Card>
      </div>
      <CustomModal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContainTitle>
            <IconDiv>
              {show === 1 ? (
                <img src={risk} alt="" />
              ) : show === 2 ? (
                <img src={need} />
              ) : show === 3 ? (
                <img src={real} />
              ) : show === 4 ? (
                <img src={regular} />
              ) : show === 5 ? (
                <img src={maintain} />
              ) : null}
            </IconDiv>
            {show === 1 ? (
              <h5>Risk profile-based asset allocation</h5>
            ) : show === 2 ? (
              <h5>Need based product selection</h5>
            ) : show === 3 ? (
              <h5>Real-Time Portfolio Visibility at Your Fingertips</h5>
            ) : show === 4 ? (
              <h5>Regular Portfolio Check-ins</h5>
            ) : show === 5 ? (
              <h5>Maintaining Portfolio Alignment</h5>
            ) : null}
          </ContainTitle>
          {show === 1 ? (
            <>
              <p>
                Discover how our wealth management experts customize investment
                strategies based on your risk tolerance, ensuring a balanced
                portfolio aligned with your financial goals
              </p>
              <p>
                Our process begins with a thorough assessment of your risk
                tolerance. We consider various factors, including your
                investment horizon, financial situation, and personal
                preferences, to determine the level of risk you are comfortable
                taking. This risk profile forms the foundation of our asset
                allocation strategy, guiding our decisions on how to distribute
                your investments across different asset classes such as
                equities, bonds, real estate, and alternative investments.
              </p>
              <p>
                By aligning your portfolio with your risk tolerance, we aim to
                achieve a balanced mix that offers growth potential while
                mitigating unnecessary risks. Our continuous monitoring and
                regular reviews ensure that your portfolio remains aligned with
                your changing financial goals and market conditions. This
                dynamic approach helps you stay on track to achieve your
                long-term objectives, providing peace of mind and confidence in
                your financial future.
              </p>
            </>
          ) : show === 2 ? (
            <>
              <p>
                Our wealth management approach prioritizes your unique financial
                needs, goals, and circumstances. Need-based product selection is
                at the heart of our strategy, ensuring that every investment
                recommendation is tailored specifically to you.
              </p>
              <p>
                We begin with an in-depth analysis of your financial situation,
                including your income, expenses, savings, and existing
                investments. By understanding your short-term needs and
                long-term aspirations, we can identify the financial products
                that best suit your objectives. Whether you aim to save for
                retirement, fund your children’s education, purchase a home, or
                achieve any other financial milestone, our team is dedicated to
                crafting a personalized plan.
              </p>
              <p>
                In addition to selecting the right products, we also provide
                ongoing support and adjustments to your portfolio as your needs
                evolve. This dynamic and proactive approach helps ensure that
                your investment strategy remains aligned with your changing
                circumstances and goals.
              </p>
            </>
          ) : show === 3 ? (
            <>
              <p>
                Our advanced wealth management platform offers real-time
                portfolio visibility at your fingertips, empowering you to stay
                informed and make timely decisions with confidence
              </p>

              <p>
                With our intuitive and user-friendly interface, you can access
                detailed insights into your portfolio's performance anytime,
                anywhere. Monitor your investments, track market trends, and
                view real-time data on asset allocation, returns, and risk
                exposure. Our platform provides comprehensive reports and
                visualizations, helping you understand your financial position
                at a glance.
              </p>

              <p>
                Beyond just visibility, our platform fosters better
                communication with your financial advisor. Share insights,
                discuss strategies, and collaborate more effectively, ensuring
                that your investment plan remains aligned with your goals and
                risk tolerance.
              </p>
            </>
          ) : show === 4 ? (
            <>
              <p>
                Our wealth management service offers structured, consistent
                portfolio reviews, providing you with the confidence and insight
                needed to navigate the ever-changing financial landscape.
              </p>

              <p>
                During these check-ins, our dedicated advisors conduct a
                thorough analysis of your portfolio’s performance. We assess
                each investment's returns, risks, and alignment with your
                long-term objectives. By reviewing your asset allocation, we
                ensure that your portfolio remains balanced and diversified,
                adapting to any shifts in market trends or your personal
                circumstances.
              </p>

              <p>
                Additionally, our check-ins provide educational insights,
                helping you understand market dynamics and the rationale behind
                our investment decisions. We believe that informed clients are
                empowered clients, and we strive to keep you well-informed every
                step of the way.
              </p>
            </>
          ) : show === 5 ? (
            <>
              <p>
                Our wealth management service emphasizes continuous portfolio
                alignment to ensure your investments remain on track, adapting
                to both market changes and your evolving personal circumstances.
              </p>

              <p>
                Our approach begins with a thorough understanding of your
                financial objectives, risk tolerance, and time horizon. With
                this foundation, we craft a diversified portfolio designed to
                meet your specific needs. However, alignment doesn’t stop at
                initial setup. Regular reviews and adjustments are critical to
                maintaining this balance.
              </p>

              <p>
                By maintaining portfolio alignment, we help you navigate the
                complexities of investing, ensuring your portfolio consistently
                supports your financial ambitions. Moreover, we provide
                transparent communication and detailed reports, keeping you
                informed about your portfolio’s status and the reasons behind
                any adjustments. This ensures you understand how your
                investments are performing and the strategy guiding them.
              </p>
            </>
          ) : null}
        </Modal.Body>
      </CustomModal>
    </Processed>
  );
}

export default Process;

export const Processed = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 30px 10px 30px 40px;
  .subdiv {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  @media (max-width: 576px) {
    padding: 0px 10px;
    .subdiv {
      margin-top: 0;
    }
  }
  @media (min-width: 1401px) {
    .subdiv {
      margin-top: 20px;
    }
  }
  @media (min-width: 1998px) {
    .subdiv {
      margin-top: 40px;
    }
  }
`;

const Card = styled.div`
  width: calc(100% - 100px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 50px;
  column-gap: 60px;
  &.animate {
    animation: ${FadeOut} 3s forwards;
  }
  .cards {
    background: #37373733;
    box-shadow: 0px 0px 4px 0px #dadada;
    width: 225px;
    height: 200px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    align-items: center;
    cursor: pointer;
  }
  .top {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #fff;
    span {
      color: #a2a2a2;
      font-size: 11px;
      margin: 0;
    }
  }
  .image {
    display: flex;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin: 5px 0;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    h6 {
      font-family: "Montserrat", sans-serif;
      font-size: 13px;
      font-weight: 400;
      text-align: left;
      color: #fff;
    }

    p {
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      text-align: left;
      color: #a2a2a2;
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 10px;
    row-gap: 20px;
    flex-direction: column;
    &.animate {
      animation: none;
    }
    .cards {
      width: calc(100% / 1);
      /* padding: 20px 20px 60px 20px; */
      height: 320.42px;
    }
    .top {
      font-size: 16px;
      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
      }
    }
    .image {
      width: 66.52px;
      height: 64.46px;
      margin-bottom: 20px;
    }
    .content {
      h6 {
        font-size: 16px;
        line-height: 19.36px;
        text-align: center;
      }
      p {
        font-size: 14px;
        line-height: 16.94px;
      }
    }
  }
  @media (min-width: 769px) {
    align-items: center;
    justify-content: unset;
    .cards:nth-child(1),
    .cards:nth-child(2),
    .cards:nth-child(3) {
      width: 25%;
      height: calc(73vh / 2);
    }

    .cards:nth-child(4),
    .cards:nth-child(5) {
      width: 25%;
      height: calc(73vh / 2);
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
    .cards {
      max-width: 270px;
      max-height: 230px;
      padding: 10px;

      /* max-height: 200px;
      max-width: 240px; */
    }
    .content {
      h6 {
        font-size: 13px;
        font-weight: 400;
        line-height: 15.36px;
        text-align: left;
      }
      p {
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        text-align: left;
      }
    }
  }
  @media (min-width: 1401px) and (max-width: 1510px) {
    .cards {
      padding-bottom: 25px;
      margin-left: 2rem;
      /* max-height: 345px;
        max-width: 335px; */
      .image {
        width: 51.84px;
        height: 51.77px;
        margin-bottom: 7px;
        margin-top: 0;
      }
      .top {
        margin: 0;
        font-size: 16px;
        line-height: 0;
        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 16.36px;
        }
      }
      .content {
        h6 {
          font-size: 15px;
          font-weight: 400;
          line-height: 17.36px;
          text-align: left;
        }
        p {
          font-size: 13px;
          font-weight: 400;
          line-height: 18.94px;
          text-align: left;
        }
      }
    }
  }
  @media (min-width: 1511px) and (max-width: 1535px) {
    .cards {
      padding-bottom: 25px;
      margin-left: 2.7rem;
      max-height: 345px;
      max-width: 335px;
      .image {
        width: 61.84px;
        height: 61.77px;
        margin-bottom: 7px;
        margin-top: 0;
      }
      .top {
        margin: 0;
        font-size: 16px;
        line-height: 0;
        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 16.36px;
        }
      }
      .content {
        h6 {
          font-size: 17px;
          font-weight: 400;
          line-height: 19.36px;
          text-align: left;
        }
        p {
          font-size: 15px;
          font-weight: 400;
          line-height: 18.94px;
          text-align: left;
        }
      }
    }
  }
  @media (min-width: 1536px) and (max-width: 1700px) {
    padding-bottom: 30px;
    .cards {
      max-height: 350px;
      max-width: 350px;
      margin-left: 3.6rem;

      .image {
        width: 63.84px;
        height: 63.77px;
        margin-bottom: 8px;
        margin-top: 0;
      }
      .top {
        margin: 0;
        font-size: 16px;
        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 0;
          margin: 0;
        }
      }
      .content {
        h6 {
          margin-top: 0;
          font-size: 16.5px;
          font-weight: 400;
          line-height: 19px;
          text-align: left;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
        }
      }
    }
  }
  @media (min-width: 1701px) and (max-width: 1998px) {
    padding-bottom: 40px;
    .cards {
      max-height: 320px;
      max-width: 320px;
      margin-left: 6rem;
    }
    .image {
      width: 66.52px;
      height: 66.61px;
      margin-bottom: 20px;
    }
    .top {
      font-size: 16px;
      span {
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.5px;
      }
    }
    .content {
      h6 {
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.5px;
        text-align: left;
      }
      p {
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.5px;
        text-align: left;
      }
    }
  }
  @media (min-width: 1999px) {
    padding-bottom: 40px;
    .cards {
      max-height: 345px;
      max-width: 340px;
      margin-left: 10rem;
    }
    .image {
      width: 68.52px;
      height: 68.61px;
      margin-bottom: 20px;
    }
    .top {
      font-size: 16px;
      span {
        font-family: "Montserrat", sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 19.5px;
      }
    }
    .content {
      h6 {
        font-family: "Montserrat", sans-serif;
        font-size: 17px;
        font-weight: 600;
        line-height: 19.5px;
        text-align: left;
      }
      p {
        font-family: "Montserrat", sans-serif;
        font-size: 17px;
        font-weight: 400;
        line-height: 20.5px;
        text-align: left;
      }
    }
  }
`;

const CustomModal = styled(Modal)`
  .modal-content {
    background: #151414;
    border-radius: 15px;
    padding: 20px 30px;
    p {
      font-family: "Montserrat", sans-serif;
      font-optical-sizing: auto;
      font-size: 13.2px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #a2a2a2;
    }
    @media (min-width: 1441px) and (max-width: 1535px) {
      p {
        font-size: 18px;
        line-height: 28px;
      }
    }
    @media (min-width: 1536px) and (max-width: 1700px) {
      p {
        font-size: 18px;
        line-height: 28px;
      }
    }
    @media (min-width: 1701px) {
      p {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .modal-header {
      border-bottom: none;
      margin: 0;
      padding: 0;
    }
    .btn-close {
      filter: invert(1.5);
    }
    .btn-close:hover {
      filter: invert(0.75);
    }
  }
`;

const ContainTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  column-gap: 20px;
  margin-bottom: 20px;
  h5 {
    color: #fff;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    h5 {
      font-size: 20px;
      font-weight: 400;
      line-height: 24.2px;
      text-align: center;
    }
  }
  @media (min-width: 1441px) and (max-width: 1536px) {
    h5 {
      font-size: 24px;
      line-height: 30px;
    }
  }
  @media (min-width: 1537px) and (max-width: 1700px) {
    h5 {
      font-size: 24px;
      line-height: 32px;
    }
  }
  @media (min-width: 1701px) {
    h5 {
      font-size: 28px;
    }
  }
`;

const IconDiv = styled.div`
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    width: 66.52px;
    height: 66.46px;
  }
  @media (min-width: 1441px) and (max-width: 1536px) {
    width: 68px;
    height: 68px;
  }
  @media (min-width: 1537px) and (max-width: 1700px) {
    width: 72px;
    height: 72px;
  }
  @media (min-width: 1701px) {
    width: 75px;
    height: 75px;
  }
`;
