import React, { useState } from "react";
import styled from "styled-components";
import { HomeDiv, Indicatored } from "../Home/Home";
import Header from "../../Components/Header/Header";
import { TextField } from "@mui/material";
import Indicator from "../../Components/Indicator/Indicator";
import call from "../../Asset/phone.png";
import sms from "../../Asset/message.png";
import location from "../../Asset/location.png";
function Contactus() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  return (
    <CuHomeDiv>
      <Header />
      <ContactDiv>
        <h1 className="mobile">Communicate with Us</h1>
        <span className="mobile">Any questions? Just write us a message!</span>
        <div className="formobile">
          <ContactFormDivMobile>
            <h1>Further Inquiries</h1>
            <span>
              let's start a conversation and explore how we can assist you!
            </span>
            <Info className="d-flex flex-column h-100">
              <div className="forSmall">
                <div className="column_gap">
                  <img src={call} alt="" />
                  <span>+91 9037740218</span>
                </div>
                <div className="column_gap">
                  <img src={sms} alt="" />
                  <span>info@cloverfieldwealth.com</span>
                </div>
                <div className="column_gap">
                  <img src={location} alt="" />
                  <span>Kaloor, Ernakulam</span>
                </div>
              </div>
            </Info>
          </ContactFormDivMobile>
          <ContactFormDiv>
            <h1>Let’s Get in touch</h1>
            <span>Fill up the form and our team will get back to you </span>
            <InputAll>
              <div className="d-flex gap-3">
                <InputDiv>
                  <label>First Name</label>
                  <InputField
                    id="standard-basic"
                    placeholder="Enter your name"
                    variant="standard"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                  />
                </InputDiv>
                <InputDiv>
                  <label>Last Name</label>
                  <InputField
                    id="standard-basic"
                    placeholder="Enter your name"
                    variant="standard"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                  />
                </InputDiv>
              </div>
              <div className="d-flex gap-3">
                <InputDiv>
                  <label>Email</label>
                  <InputField
                    id="standard-basic"
                    placeholder="Enter Firm Email Address"
                    variant="standard"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputDiv>
                <InputDiv>
                  <label>Phone</label>
                  <InputField
                    id="standard-basic"
                    placeholder="Enter Firm phone Number"
                    variant="standard"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </InputDiv>
              </div>
              <InputDiv>
                <label>Message</label>
                <InputField
                  id="standard-basic"
                  placeholder="Enter Message here"
                  variant="standard"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </InputDiv>
              <ButtonDiv>Submit</ButtonDiv>
            </InputAll>
          </ContactFormDiv>
        </div>
      </ContactDiv>
      <Indicatored>
        <Indicator numberOfSlides={0} />
      </Indicatored>
    </CuHomeDiv>
  );
}

export default Contactus;

export const CuHomeDiv = styled(HomeDiv)`
  height: 100vh;
  width: 100vw;
  position: relative;
  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    background-color: #000;
  }
`;

const ContactDiv = styled.div`
  padding: 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  .formobile {
    display: flex;
    width: 100%;
    padding: 0 50px;
    column-gap: 30px;
  }
  h1 {
    font-family: "Founders Grotesk", sans-serif;
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    line-height: 2.6rem;
    margin: 0;
  }
  span {
    font-family: "Founders Grotesk", sans-serif;
    color: #a6a6a6;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }
  @media (max-width: 768px) {
    padding: 0 0;
    justify-content: unset;
    .mobile {
      display: none;
    }
    .formobile {
      flex-direction: column-reverse;
      width: 100%;
      padding: 0;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    h1 {
      font-size: 50px;
      font-weight: 600;
      line-height: 63px;
    }
    span {
      font-size: 18px;
    }
  }
  @media (min-width: 1701px) {
    h1 {
      font-size: 65px;
      font-weight: 600;
      line-height: 78px;
    }
    span {
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
    }
  }
`;

const ContactFormDiv = styled.div`
  background: #0c0c0ccc;
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  border-radius: 15px;
  width: 70%;
  margin-top: 18px;
  h1 {
    font-family: "Founders Grotesk", sans-serif;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 600;
    color: #fff;
  }
  span {
    font-family: "Founders Grotesk", sans-serif;
    color: #a6a6a6;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
  @media (max-width: 768px) {
    padding: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
    h1 {
      font-family: "Founders Grotesk", sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      text-align: center;
      margin-bottom: 5px;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    margin-top: 20px;
    h1 {
      font-size: 34px;
      font-weight: 600;
      line-height: 42px;
      text-align: left;
      margin-bottom: 8px;
    }
    span {
      font-size: 16px;
    }
  }
  @media (min-width: 1701px) {
    padding: 3rem;
    margin-top: 30px;
    h1 {
      font-size: 42px;
      font-weight: 600;
      line-height: 50px;
      margin-bottom: 10px;
    }
    span {
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
    }
  }
`;

export const InputDiv = styled.div`
  width: 100%;
  padding: 10px 0;
  label {
    color: #c2c2c2;
    font-size: 16px;
    @media (min-width: 1441px) and (max-width: 1700px) {
      font-size: 20px;
      font-weight: 400;
      line-height: 24.2px;
      text-align: left;
      margin-bottom: 15px;
    }
    @media (min-width: 1700px) {
      font-size: 24px;
      font-weight: 400;
      line-height: 22.2px;
      text-align: left;
      margin-bottom: 25px;
    }
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    margin-bottom: 0.3rem;
  }
  @media (min-width: 1701px) {
    margin-bottom: 1.5rem;
  }
`;

const InputField = styled(TextField)`
  width: 100%;
  margin: 0;
  padding: 0;

  .MuiInputBase-input {
    color: #fff;

    &::placeholder {
      color: #9c9c9c66;
      padding-left: 10px;
    }
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #9c9c9c66;
    margin-right: 10px;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #fff;
  }

  .MuiInput-underline:after {
    border-bottom: 2px solid #fff;
  }
`;

const ButtonDiv = styled.button`
  background: #ffffffe3;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  font-family: "Founders Grotesk", sans-serif;
  line-height: 24px;
  padding: 8px 20px;
  border-radius: 11px;
  border: 1px solid #000;
  width: 100%;
  margin-top: 20px;
  @media (min-width: 1441px) and (max-width: 1700px) {
    margin-top: 3rem;
    padding: 13px;
  }
  @media (min-width: 1701px) {
    margin-top: 3rem;
    padding: 15px;
  }
`;

const InputAll = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 10px 35px 0 35px;
  @media (max-width: 768px) {
    padding: 10px 30px;
    div {
      flex-direction: column;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    padding-top: 0.5rem;
  }
  @media (min-width: 1701px) {
    padding-top: 2rem;
  }
`;

const Info = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  img {
    width: 19px;
    height: 19px;
    object-fit: cover;
  }
  span {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20.2px;
    text-align: left;
    color: #fff;
  }
  .column_gap {
    display: flex;
    column-gap: 30px;
  }
  .forSmall {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
  }
  @media (max-width: 768px) {
    .forSmall {
      row-gap: 20px;
      margin: 20px 0;
    }
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    img {
      width: 25px;
      height: 25px;
    }
    span {
      font-size: 16px;
      line-height: 20.2px;
    }
  }
  @media (min-width: 1701px) {
    img {
      width: 29px;
      height: 29px;
    }
    span {
      font-size: 20px;
      font-weight: 500;
      line-height: 24.2px;
    }
  }
`;
const ContactFormDivMobile = styled(ContactFormDiv)`
  width: 30%;
  justify-content: center;
  align-items: center;
  span {
    text-align: center;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 10px;
    .forSmall {
      display: flex;
      flex-direction: column;
      column-gap: 30px;
    }
  }
`;
