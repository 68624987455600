import React from "react";
import intro from "../../Asset/home/piano.jpg";
import styled, { keyframes } from "styled-components";
import { useInView } from "react-intersection-observer";
import { ForHeader } from "./Home";
import Header from "../../Components/Header/Header";
import HeaderForHover from "../../Components/Header/HeaderForHover";

function Introduct() {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });
  return (
    <Introduction ref={ref} className={inView ? "animate" : ""}>
      <HeaderForHover />
      <div className="intro">
        <h1>COMPANY INTRODUCTION</h1>
        <p className="text-justify">
          Introducing Cloverfield Wealth, a pioneering wealth management firm
          dedicated to guiding individuals and corporates towards financial
          prosperity and security. As a newly established company, we bring a
          fresh perspective to the industry, fueled by innovation, expertise,
          and a steadfast commitment to our clients' success. At Cloverfield
          Wealth, we understand that each client's financial journey is unique,
          which is why we offer personalized wealth management solutions
          tailored to their specific needs and aspirations. With a focus on
          transparency, integrity, and client-centricity, we strive to build
          lasting relationships based on trust and mutual respect. Our goal is
          to empower our clients to make informed decisions, navigate financial
          complexities, and achieve their long-term financial goals with
          confidence. Welcome to Cloverfield Wealth, where your financial future
          begins.
        </p>
      </div>
      <div className="imageCon">
        <img src={intro} alt="" className="img-fluid" />
      </div>
    </Introduction>
  );
}

export default Introduct;

export const MoveTop = keyframes`
  from {
    transform: translateY(15px);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const FadeOut = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Introduction = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 0 4.5rem 0 3rem;
  display: flex;
  position: relative;
  &.animate .intro p {
    animation: ${FadeOut} 3s forwards;
  }
  &.animate .intro h1 {
    animation: ${MoveTop} 3s forwards;
  }
  .intro {
    display: flex;
    width: 40vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    padding: 0 4rem 0 0;
    h1 {
      font-family: "Montserrat", sans-serif;
      font-optical-sizing: auto;
      font-style: normal;
      font-size: 22px;
      font-weight: 700;
      line-height: 40px;
      text-align: left;
      color: #fff;
    }
    p {
      font-family: "Montserrat", sans-serif;
      font-optical-sizing: auto;
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      text-align: left;
      color: #9e9e9e;
      margin-top: 10px;
    }
  }

  .imageCon {
    width: 60vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 0;

    img {
      width: calc(100% - 10%);
      border-radius: 20px;
      filter: grayscale(100%);
      height: calc(100vh - 30%);
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin-right: 0;
    justify-content: center;
    align-items: center;
    &.animate .intro p {
      animation: none;
    }
    &.animate .intro h1 {
      animation: none;
    }
    .intro {
      width: 100%;
      padding: 0 20px;
      height: 100%;

      h1 {
        font-size: 25px;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
      }

      p {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
      }
    }

    .imageCon {
      width: 100%;
      padding: 10px 20px 0 20px;
      height: 100%;
      object-fit: cover;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (min-width: 769px) and (max-width: 992px) {
    padding: 20px 30px 20px 30px;
    width: 100%;
    height: 100%;
    margin-right: 0;
    justify-content: center;
    align-items: center;
    .intro {
      display: flex;
      justify-content: center;
      padding: 0 20px 0 0;
      height: 100%;
      width: 40%;
      h1 {
        font-size: 18px;
        margin-bottom: 0;
      }
      p {
        font-size: 12px;
      }
    }
    .imageCon {
      padding: 0 30px 0 0;
      width: 60%;
      height: 100%;
    }
  }

  @media (min-width: 993px) and (max-width: 1200px) {
    .intro {
      padding: 0 50px 0 0;

      h1 {
        font-size: 20px;
        line-height: 16px;
      }

      p {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  @media (min-width: 1441px) and (max-width: 1550px) {
    .intro {
      padding: 0 30px 0 0;
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 17px;
        line-height: 25px;
      }
    }
  }
  @media (min-width: 1551px) and (max-width: 1700px) {
    .intro {
      padding: 0 30px 0 0;
      h1 {
        font-size: 32px;
      }
      p {
        font-size: 18px;
        line-height: 28px;
      }
    }
    .imageCon {
      padding: 60px 70px 60px 40px;
    }
  }

  @media (min-width: 1701px) {
    .intro {
      padding: 0 40px 0 0;

      h1 {
        font-size: 41.6px;
        font-weight: 800;
        line-height: 50.71px;
        text-align: left;
      }

      p {
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
      }
    }

    .imageCon {
      padding: 60px 50px 60px 40px;
    }
  }
`;
