import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HomeDiv, Indicatored, Section } from "../Home/Home";
import Insight1 from "./Insight1";
import Insight_2 from "./Insight_2";
import Indicator from "../../Components/Indicator/Indicator";
import gif from "../../Asset/home/insight/gif.gif";
import ReactPageScroller from "react-page-scroller";
import Header from "../../Components/Header/Header";
import { FadeOut } from "../Home/Introduct";

function Insight() {
  const [currentPage, setCurrentPage] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const handleIndicatorClick = (page) => {
    if (isMobile) {
      const section = document.getElementById(`section-${page}`);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        setCurrentPage(page - 1);
      }
    } else {
      setCurrentPage(page - 1);
    }
  };

  const handlePageChange = (number) => {
    setCurrentPage(number);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <HomeDiv>
      {isMobile ? (
        <>
          <Header />
          <Section id="section-1">
            <Insight1 />
          </Section>
          <Section id="section-2">
            <Insight_2 />
          </Section>
        </>
      ) : (
        <>
          <Header />
          <ReactPageScroller
            pageOnChange={handlePageChange}
            customPageNumber={currentPage}
          >
            <Insight1 />
            <Insight_2 />
          </ReactPageScroller>
        </>
      )}
      <Indicatored>
        <Indicator
          numberOfSlides={2}
          currentPage={currentPage + 1} // Convert 0-based index to 1-based index
          onIndicatorClick={handleIndicatorClick}
        />
      </Indicatored>
    </HomeDiv>
  );
}

export default Insight;

export const InsightDiv = styled.div`
  position: relative;
  background-color: #000;
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
  justify-content: center;
  padding: 10px;
  &.animate {
    animation: ${FadeOut} 3s forwards;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 10px 30px;
    row-gap: 0;
    justify-content: unset;
    .column_gap {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  @media (min-width: 577px) and (max-width: 768px) {
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
  }
  @media (min-width: 1701px) {
  }
`;
