import React, { useState } from "react";
import styled from "styled-components";
import sms from "../../Asset/home/sms.png";
import msg from "../../Asset/home/msg.png";
import arrow from "../../Asset/home/arrow.png";
import { PiSmileyThin } from "react-icons/pi";

function Indicator({ numberOfSlides, currentPage, onIndicatorClick }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const indicators = Array.from({ length: numberOfSlides }, (_, index) => (
    <Indicatored
      key={index}
      active={index + 1 === currentPage}
      onClick={() => onIndicatorClick(index + 1)}
    />
  ));

  return (
    <IndoSms>
      <IndicatorsContainer>{indicators}</IndicatorsContainer>
      {/* <SmsContainer onClick={handleShow}>
        <img src={sms} alt="" />
      </SmsContainer>
      {show && (
        <MessagePopup
          message="Hello How are you"
          senderName="Adithya Rag"
          senderImage={msg}
          timestamp="Just now"
          handleClose={handleClose}
        />
      )} */}
    </IndoSms>
  );
}
const MessagePopup = ({
  message,
  senderName,
  senderImage,
  timestamp,
  handleClose,
}) => {
  return (
    <PopupContainer>
      <CloseButton onClick={handleClose}>&times;</CloseButton>
      <MessageContainer>
        <ProfileImage src={senderImage} alt={senderName} />
        <MessageContent>
          <MessageText>
            {message}
            <MessageFooter>
              <SenderName>{senderName}</SenderName>
              <Timestamp>{timestamp}</Timestamp>
            </MessageFooter>
          </MessageText>
        </MessageContent>
      </MessageContainer>
      <ReplyContainer>
        <ReplyInput placeholder="Write a Reply" />
        <EmojiButton>
          <PiSmileyThin />
        </EmojiButton>
        <SendButton>
          <img src={arrow} />
        </SendButton>
      </ReplyContainer>
    </PopupContainer>
  );
};
export default Indicator;

export const IndoSms = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 11px;
  align-items: center;
  margin: 0;
  max-width: 100px;
  position: absolute;
  right: 20px;
  top: 0;
`;

export const IndicatorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 50px;
  height: calc(100% - 32px);
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Indicatored = styled.div`
  border-radius: 50%;
  height: 15px;
  width: 15px;
  border: 1px solid #ffffffb2;
  background: ${({ active }) => (active ? "#fff" : "transparent")};
  cursor: pointer;
`;

export const SmsContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #373535;
  background: #7575751a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 8px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 768px) {
    display: none;
    position: absolute;
    padding: 14px 12px;
    right: 0;
    bottom: 25px;
    width: 45px;
    height: 45px;
  }
`;
export const PopupContainer = styled.div`
  position: absolute;
  width: 300px;
  background-color: #75757587;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-family: Arial, sans-serif;
  color: #fff;
  bottom: 12%;
  right: 20%;
  @media (max-width:768px){
    width: 300px;
    bottom: 16%;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -30px;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #75757540;
  border-radius: 100%;
  width: 20px;
  height: 20px;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin-right: 10px;
  margin-top: 10%;
`;

const MessageContent = styled.div`
  flex: 1;
`;

const MessageText = styled.div`
  background-color: #75757533;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
`;

const MessageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #ccc;
  margin-top: 5px;
`;

const SenderName = styled.span``;

const Timestamp = styled.span``;

const ReplyContainer = styled.div`
  background-color: #75757533;
  display: flex;
  align-items: center;
  margin-top: 10px;
  border-radius: 10px;
`;

const ReplyInput = styled.input`
  flex: 1;
  padding: 5px;
  border-radius: 10px;
  border: none;
  outline: none;
  margin-right: 10px;
  background-color: #75757533;
  color: #fff;

  ::placeholder {
    color: #fff;
    opacity: 1;
  }
`;

const EmojiButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  margin-right: 5px;
`;

const SendButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
`;
