import React from "react";
import { Introduction } from "../Home/Introduct";
import expertise from "../../Asset/home/Mask group.png";
import styled from "styled-components";
import HeaderForHover from "../../Components/Header/HeaderForHover";
import { useInView } from "react-intersection-observer";
function Abt_section_2() {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });
  return (
    <Intro ref={ref} className={inView ? "animate" : ""}>
      <HeaderForHover />
      <div className="intro">
        <h1>WITH YOU AT EVERY STEP</h1>
        <p>
          We recognize the dedication required to accumulate and maintain your
          wealth, and we are here to help you go the extra mile. At Cloverfield
          Wealth, you don’t need to worry—we're with you every step of the way.
          We offer comprehensive private wealth management solutions tailored
          for high-net-worth individuals, business families, corporations, and
          non-profit organizations. We aspire to be your most trusted wealth
          advisors, upholding the highest standards of conduct and service in
          all our dealings. Our sophisticated yet simple financial planning and
          wealth management services reflect our understanding that wealth is
          more than just numbers. With deep knowledge of today’s financial world
          and sensitivity to future trends, we offer timely and impactful
          portfolio management services. Our experts bring insights and
          experience from both domestic and international markets to create
          innovative and adaptable solutions tailored to your needs. We offer
          unique products and services, leveraging our group resources and
          extensive industry network. Our goal is to craft optimal wealth
          management and advisory solutions that help you achieve your financial
          and strategic goals.
        </p>
      </div>
      <div className="imageCon">
        {/* <img src={rectangle}/> */}
        <img src={expertise} alt="" />
      </div>
    </Intro>
  );
}

export default Abt_section_2;

const Intro = styled(Introduction)`
  background: radial-gradient(
    44.97% 107.61% at 58.28% 52.51%,
    #222222 0%,
    #000000 100%
  );
  @media (max-width: 768px) {
    .intro {
      h1 {
        text-align: left;
      }
    }
  }
`;
