import React, { useEffect, useState } from "react";
import {
  SectionCover,
  SectionHead,
} from "../../Components/PublicStyles/StyledComp";
import { useInView } from "react-intersection-observer";
import HeaderForHover from "../../Components/Header/HeaderForHover";
import { MainDiv } from "./Philosaophy";
import styled from "styled-components";
//one
import AdityaC from "../../Asset/Partners/onePage/Aditya C.png";
import AdityaD from "../../Asset/Partners/onePage/Aditya D.png";
import AxisD from "../../Asset/Partners/onePage/Axis D.png";
import AxisC from "../../Asset/Partners/onePage/Axis C.png";
import BandhanC from "../../Asset/Partners/onePage/bandhan C.png";
import BandhanD from "../../Asset/Partners/onePage/bandhan D.png";
import CaneraC from "../../Asset/Partners/onePage/Canera C.png";
import CaneraD from "../../Asset/Partners/onePage/Canera D.png";
import dspC from "../../Asset/Partners/onePage/dsp C.png";
import dspD from "../../Asset/Partners/onePage/dsp D.png";
import EdelweissC from "../../Asset/Partners/onePage/Edelweiss C.png";
import EdelweissD from "../../Asset/Partners/onePage/Edelweiss D.png";
import franklinC from "../../Asset/Partners/fourPage/franklin C.png";
import franklinD from "../../Asset/Partners/fourPage/franklin D.png";
import hdfcC from "../../Asset/Partners/onePage/hdfc C.png";
import hdfcD from "../../Asset/Partners/onePage/hdfc D.png";
import bajajC from "../../Asset/Partners/onePage/bajaj C.png";
import bajajD from "../../Asset/Partners/onePage/bajaj D.png";
import heliosC from "../../Asset/Partners/onePage/helios C.png";
import heliosD from "../../Asset/Partners/onePage/helios D.png";
import iciciC from "../../Asset/Partners/onePage/icici C.png";
import iciciD from "../../Asset/Partners/onePage/icici D.png";
//two
import jmC from "../../Asset/Partners/twoPage/jm C.png";
import jmD from "../../Asset/Partners/twoPage/jm D.png";
import invescoC from "../../Asset/Partners/twoPage/invesco C.png";
import invescoD from "../../Asset/Partners/twoPage/invesco D.png";
import kotakC from "../../Asset/Partners/twoPage/kotak C.png";
import kotakD from "../../Asset/Partners/twoPage/kotak D.png";
import mahindraC from "../../Asset/Partners/twoPage/mahindra C.png";
import mahindraD from "../../Asset/Partners/twoPage/mahindra D.png";
import miraeC from "../../Asset/Partners/twoPage/mirae C.png";
import miraeD from "../../Asset/Partners/twoPage/mirae D.png";
import motilalC from "../../Asset/Partners/twoPage/motilal C.png";
import motilalD from "../../Asset/Partners/twoPage/motilal D.png";
import tataC from "../../Asset/Partners/twoPage/tata C.png";
import tataD from "../../Asset/Partners/twoPage/tata D.png";
import nipponC from "../../Asset/Partners/twoPage/nippon C.png";
import nipponD from "../../Asset/Partners/twoPage/nippon D.png";
import quantC from "../../Asset/Partners/twoPage/quant C.png";
import quantD from "../../Asset/Partners/twoPage/quant D.png";
import utiC from "../../Asset/Partners/threePage/UTI C.png";
import utiD from "../../Asset/Partners/threePage/UTI D.png";
import pgimC from "../../Asset/Partners/threePage/pgim C.png";
import pgimD from "../../Asset/Partners/threePage/pgim D.png";
import sundaramC from "../../Asset/Partners/threePage/sundaram C.png";
import sundaramD from "../../Asset/Partners/threePage/sundaram D.png";
import whiteC from "../../Asset/Partners/threePage/white C.png";
import whiteD from "../../Asset/Partners/threePage/white D.png";
import sbiC from "../../Asset/Partners/threePage/sbi C.png";
import sbiD from "../../Asset/Partners/threePage/sbi D.png";
import hsbcC from "../../Asset/Partners/onePage/hsbc C.png";
import hsbcD from "../../Asset/Partners/onePage/hsbc D.png";
import barodaC from "../../Asset/Partners/fourPage/baroda C.png";
import barodaD from "../../Asset/Partners/fourPage/baroda D.png";

import { Indicatored } from "../../Components/Indicator/Indicator";
import { Link } from "react-router-dom";

function Partners() {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [pageImages, setPageImages] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 574);

  const getRightValue = () => {
    switch (currentPage) {
      case 2:
        return "770px";
      case 3:
        return "250px";
      default:
        return "400px";
    }
  };

  const images = [
    { name: "Aditya", srcD: AdityaD, srcC: AdityaC },
    { name: "Axis", srcD: AxisD, srcC: AxisC },
    { name: "Bajaj", srcD: bajajD, srcC: bajajC },
    { name: "Bandhan", srcD: BandhanD, srcC: BandhanC },
    { name: "Canera", srcD: CaneraD, srcC: CaneraC },
    { name: "Dsp", srcD: dspD, srcC: dspC },
    { name: "Edelweiss", srcD: EdelweissD, srcC: EdelweissC },
    { name: "Franklin", srcD: franklinD, srcC: franklinC },
    { name: "hdfc", srcD: hdfcD, srcC: hdfcC },
    { name: "helieos", srcD: heliosD, srcC: heliosC },
    { name: "HSBC", srcD: hsbcD, srcC: hsbcC },
    { name: "icici", srcD: iciciD, srcC: iciciC },
    { name: "Invesco", srcD: invescoD, srcC: invescoC },
    { name: "jm", srcD: jmD, srcC: jmC },
    { name: "kotak", srcD: kotakD, srcC: kotakC },
    { name: "mahindra", srcD: mahindraD, srcC: mahindraC },
    { name: "Mirae", srcD: miraeD, srcC: miraeC },
    { name: "Motilal", srcD: motilalD, srcC: motilalC },
    { name: "Nippon", srcD: nipponD, srcC: nipponC },
    { name: "PGIM", srcD: pgimD, srcC: pgimC },
    { name: "Quant", srcD: quantD, srcC: quantC },
    { name: "Sundaram", srcD: sundaramD, srcC: sundaramC },
    { name: "tata", srcD: tataD, srcC: tataC },
    { name: "UTI", srcD: utiD, srcC: utiC },
    { name: "white", srcD: whiteD, srcC: whiteC },
    { name: "sbi", srcD: sbiD, srcC: sbiC },
    { name: "baroda", srcD: barodaD, srcC: barodaC },
  ];

  const imagesPerPage = 12; // Number of images per page
  const totalPages = Math.ceil(images.length / imagesPerPage);

  useEffect(() => {
    const startIndex = (currentPage - 1) * imagesPerPage;
    setPageImages(images.slice(startIndex, startIndex + imagesPerPage));

    // Listener to detect screen width change
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 574);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [currentPage]);

  const handleHover = (name) => {
    setHoveredImage(name);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const handleOnClick = (page) => {
    if (page >= 1 && page <= Math.ceil(images.length / imagesPerPage)) {
      setCurrentPage(page);
    }
  };

  const indicators = Array.from(
    { length: Math.ceil(images.length / imagesPerPage) },
    (_, index) => (
      <Indicatored
        key={index}
        active={index + 1 === currentPage}
        onClick={() => handleOnClick(index + 1)}
      />
    )
  );
  const isLastPage = currentPage === totalPages;
  const mainDivStyle = isLastPage ? { height: "500px" } : {};

  return (
    <SectionCover ref={ref} style={{ flexDirection: "column" }}>
      <HeaderForHover />
      <MainDiv>
        <SectionHead className={inView ? "animate" : ""}>
          Our Partners
        </SectionHead>
      </MainDiv>
      <AdjustedMainDiv style={mainDivStyle}>
        <PartnerIcon>
          {pageImages.map((img, index) => (
            <div
              key={index}
              className="one"
              onMouseEnter={() => handleHover(img.name)}
              onMouseLeave={handleMouseLeave}
            >
              <img
                src={
                  isMobileView
                    ? img.srcD // Show srcC on mobile screens
                    : hoveredImage === img.name
                    ? img.srcC // Show srcC on hover
                    : img.srcD // Show srcD on non-hover
                }
                alt={img.name}
              />
            </div>
          ))}
        </PartnerIcon>
      </AdjustedMainDiv>
      <Link to={"/?section=2"}>
        <IndiCatorContainer>{indicators}</IndiCatorContainer>
      </Link>
    </SectionCover>
  );
}

export default Partners;

const PartnerIcon = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;

  .one {
    flex: 0 0 calc(25% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;

    img {
      width: auto;
      height: 90px;
      object-fit: contain;
      transition: all 0.3s ease;
    }
  }

  @media (max-width: 1200px) {
    .one img {
      /* max-width: 70%; */
    }
  }
  @media (min-width: 1201px) and (max-width: 1535px) {
    .one img {
      height: 95px
      /* max-width: 70%; */
    }
  }

  @media (max-width: 992px) {
    .one img {
      height: 90px;
      /* max-width: 60%; */
    }
  }
  @media (min-width: 1536px) and (max-width: 1700px) {
    .one img {
      /* max-width: 80%; */
      height: 110px;
    }
  }
  @media (min-width: 1701px) and (max-width: 1900px) {
    .one img {
      /* max-width: 90%; */
      height: 120px;
    }
  }
  @media (min-width: 1901px) {
    .one img {
      /* max-width: 100%; */
      height: 130px;
    }
  }

  @media (max-width: 576px) {
    .one {
      flex-basis: calc(50% - 20px);
      img {
        max-width: none;
        width: 100%;
        height: auto;
        padding: 10px;
      }
    }
  }
`;

const ShadowDiv = styled.div`
  width: 25vw;
  height: 80vh;
  background-color: #898886;
  position: absolute;
  top: 100px;
  /* transform: rotate(-30deg); */
  right: ${(props) => props.right};
  backdrop-filter: blur(284px);
  filter: blur(800px);
  z-index: -10;
  @media (max-width: 768px) {
    display: none;
  }
`;

const IndiCatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 50px;
  height: auto;
  margin-bottom: 50px;
`;

const AdjustedMainDiv = styled(MainDiv)`
  width: 100vw;
  height: 100vh;
  padding-top: 0;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;
