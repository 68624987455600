import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import "@fontsource/inter";
import Banner from "./Banner";
import Process from "./Process";
import Philosaophy from "./Philosaophy";
import Footer from "./Footer";
import Header from "../../Components/Header/Header";
import Indicator from "../../Components/Indicator/Indicator";
import Introduct from "./Introduct";
import ReactPageScroller from "react-page-scroller";
import Partners from "./Partners";
import { useSearchParams } from "react-router-dom";

function Home({ isMobile }) {
  const [currentPage, setCurrentPage] = useState(0);
  const section3Ref = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const employeeIdUrl = searchParams.get("section");

  useEffect(() => {
    if (isMobile && employeeIdUrl == 2) {
      handleScrollToSection3();
    }
  }, [isMobile, section3Ref.current, employeeIdUrl]);

  useEffect(() => {
    if (!isMobile && employeeIdUrl == 2) {
      setCurrentPage(employeeIdUrl);
      setSearchParams({});
    }
  }, [employeeIdUrl, section3Ref.current]);

  const handleScrollToSection3 = () => {
    if (section3Ref.current) {
      section3Ref.current.scrollIntoView({ behavior: "smooth" });
      setSearchParams({});
    }
  };

  const handleIndicatorClick = (page) => {
    if (isMobile) {
      const section = document.getElementById(`section-${page}`);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        setCurrentPage(page - 1);
      }
    } else {
      setCurrentPage(page - 1);
    }
  };

  const handlePageChange = (number) => {
    setCurrentPage(number);
  };

  return (
    <HomeDiv>
      {isMobile ? (
        <>
          <Header />
          <Section id="section-1">
            <Banner />
          </Section>
          <Section id="section">
            <Introduct />
          </Section>
          <Section id="section-3" ref={section3Ref}>
            <Partners />
          </Section>
          <Section id="section-4">
            <Process />
          </Section>
          <Section id="section-5">
            <Philosaophy />
          </Section>
          <Section id="section-6">
            <Footer />
          </Section>
        </>
      ) : (
        <ReactPageScroller
          pageOnChange={handlePageChange}
          customPageNumber={currentPage}
        >
          <>
            <Header />
            <Banner />
          </>
          <Introduct />
          <Partners />
          <Process />
          <Philosaophy />
          <Footer setCurrentPage={setCurrentPage} />
        </ReactPageScroller>
      )}
      <Indicatored>
        <Indicator
          numberOfSlides={6}
          currentPage={currentPage + 1}
          onIndicatorClick={handleIndicatorClick}
        />
      </Indicatored>
    </HomeDiv>
  );
}

export default Home;

export const HomeDiv = styled.div`
  width: 100vw;
  height: 100vh;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

export const Section = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  @media (max-width: 768px) {
    padding: 0;
    margin: 0;
    width: 100%;
    min-height: 100%;
    .externalHead {
      font-family: "Founders Grotesk", sans-serif;
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      text-align: left;
      color: #fff;
      margin-top: 20px;
    }
  }
`;

export const Indicatored = styled.div`
  position: fixed;
  height: calc(100vh - 20%);
  margin-top: 100px;
  right: 0;
  z-index: 9;
`;
