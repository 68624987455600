import React from "react";
import {
  SectionCover,
  SectionHead,
} from "../../Components/PublicStyles/StyledComp";
import styled from "styled-components";
import Philosophy from "../../Asset/home/stairway-1136071 1.png";
import { SectionDiv } from "../About/Abt_section_3";
import HeaderForHover from "../../Components/Header/HeaderForHover";
import { useInView } from "react-intersection-observer";
import { FadeOut } from "./Introduct";

function Philosaophy() {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });
  return (
    <SectionCover ref={ref}>
      <HeaderForHover />
      <MainDiv>
        <SectionHead className={inView ? "animate" : ""}>
          Our Philosophy
        </SectionHead>
        <Combine>
          <ImageDiv>
            <img src={Philosophy} alt="" />
            <InnerText>
              <span>
                To partner with our clients for their long-term Wealth creation
                backed by a strong focus on unbiased & need based advice.
              </span>
            </InnerText>
          </ImageDiv>
        </Combine>
      </MainDiv>
    </SectionCover>
  );
}

export default Philosaophy;

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px 30px 30px;
  width: 91%;
  height: calc(100vh- 20%);
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 10px 10px;
    margin-right: 0;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 20px 30px;
  }

  @media (min-width: 993px) and (max-width: 1200px) {
    padding: 20px 30px;
  }
`;
export const ImageDiv = styled.div`
  /* padding: 20px 30px 0 50px; */
  padding: 10px 0 10px 9vw;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 475px;
  img {
    border-radius: 25px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    padding: 0;
    height: 100%;
    img {
      border-radius: 15px;
    }
  }
  @media (min-width: 769px) and (max-width: 992px) {
    /* max-width: 700px; */
    height: 100%;
  }

  @media (min-width: 993px) and (max-width: 1200px) {
    height: 100%;
    width: 100%;
    /* max-height: 450px; */
  }

  @media (min-width: 1201px) and (max-width: 1440px) {
    height: 100%;
  }

  @media (min-width: 1441px) and (max-width: 1700px) {
    height: 100%;
    padding-bottom: 30px;
  }

  @media (min-width: 1701px) {
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
  }
`;

const Combine = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  &.animate {
    animation: ${FadeOut} 3s forwards;
  }
`;

export const InnerText = styled.div`
  position: absolute;
  display: flex;
  padding: 0 5.9rem;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 35.6px;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    width: 100%;
    padding: 0 10px;
  }
  @media (min-width: 1511px) and (max-width: 1535px) {
    font-size: 30px;
    padding: 0 5rem 0 5rem;
  }
  @media (min-width: 1536px) and (max-width: 1700px) {
    font-size: 32px;
    padding: 0 5rem 0 7rem;
  }
  @media (min-width: 1701px) and (max-width: 1998px) {
    font-size: 33px;
    font-weight: 700;
    line-height: 40.23px;
    text-align: left;
  }
  @media (min-width:1999px){
    font-size: 40px;
    line-height: 50.23px;
    padding: 0 7rem;
  }
`;
