import React from "react";
import { ChoiceContainer, SectionDiv } from "./Abt_section_3";
import styled from "styled-components";
import HeaderForHover from "../../Components/Header/HeaderForHover";
import { useInView } from "react-intersection-observer";
import { FadeOut } from "../Home/Introduct";
import { SectionHead } from "../../Components/PublicStyles/StyledComp";
import { CustomIntro } from "./Abt_Section_1";

function Abt_section_5() {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });
  return (
    <SectionDiv ref={ref} className={inView ? "animate" : ""}>
      <HeaderForHover />
      <SectionHead>OUR TEAM</SectionHead>
      {/* <TeamContainer>
        <TeamDiv>
          <img src={require("../../Asset/about/teams/one.png")} alt="" />
          <Info>
            <h6>John Doe</h6>
            <p>Founder & CEO</p>
          </Info>
        </TeamDiv>
        <TeamDiv>
          <img src={require("../../Asset/about/teams/two.png")} alt="" />
          <Info>
            <h6>John Doe</h6>
            <p>Founder & CEO</p>
          </Info>
        </TeamDiv>
        <TeamDiv>
          <img src={require("../../Asset/about/teams/three.png")} alt="" />
          <Info>
            <h6>John Doe</h6>
            <p>Founder & CEO</p>
          </Info>
        </TeamDiv>
        <TeamDiv>
          <img src={require("../../Asset/about/teams/four.png")} alt="" />
          <Info>
            <h6>John Doe</h6>
            <p>Founder & CEO</p>
          </Info>
        </TeamDiv>
      </TeamContainer> */}
      <CustomIntro>

    <div className="intro">
      <p>
        Cloverfield Wealth, established by seasoned financial experts with over
        two decades of experience, offers wealth management and investment
        advisory services to individuals, families, and institutions. The
        company's name is inspired by the symbolism of a "field of clover,"
        representing good fortune, prosperity, and growth. Cloverfield Wealth's
        mission is to assist clients in achieving financial security, growth,
        and success, mirroring the symbolism of a flourishing field of clover.
      </p>
      </div>
      </CustomIntro>
    </SectionDiv>
  );
}

export default Abt_section_5;

export const TeamContainer = styled(ChoiceContainer)`
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  height: 80%;
  position: relative;
  &.animate {
    animation: ${FadeOut} 3s forwards;
  }
  @media (max-width: 768px) {
    padding-right: 0;
    padding-top: 30px;
    row-gap: 20px;
    column-gap: 20px;
    justify-content: center;
    align-items: center;
  }
`;

export const Info = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  opacity: 0; // Initially hidden
  transition: opacity 0.3s ease-in-out;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  h6 {
    font-size: 16px;
    font-weight: 400;
  }
  p {
    font-size: 14px;
  }
`;

export const TeamDiv = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  height: 200px;
  max-width: 170px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    filter: grayscale(130%);
  }

  &:hover ${Info} {
    opacity: 1; // Show text on hover
  }

  @media (max-width: 768px) {
    width: calc(100% / 3);
    margin-right: 0;
    height: 250px;
    width: 160px;
  }
  @media (min-width: 577px) and (max-width: 768px) {
  }
  @media (min-width: 769px) and (max-width: 992px) {
  }
  @media (min-width: 993px) and (max-width: 1200px) {
  }
  @media (min-width: 1201px) and (max-width: 1440px) {
    max-height: 280px;
    max-width: 390px;
  }
  @media (min-width: 1441px) and (max-width: 1700px) {
    height: 280px;
    max-width: 390px;
  }
  @media (min-width: 1701px) {
    height: 320px;
    max-width: 410px;
  }
`;
